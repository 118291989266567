import axios from "axios";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

export const sendWebhook = async (webhookData) => {
  try {
    const token = Cookies.get("authToken");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await axios.post(`${API_URL}/api/SendWebhook`, webhookData, { headers });

    return response.data;
  } catch (error) {
    console.error("Erro ao enviar webhook:", error);
    throw error;
  }
};

export const updateWebhook = async (id, webhookData) => {
  try {
    const token = Cookies.get("authToken");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await axios.put(`${API_URL}/api/SendWebhook/${id}`, webhookData, { headers });

    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar webhook:", error);
    throw error;
  }
};

export const deleteWebhook = async (id) => {
  try {
    const token = Cookies.get("authToken");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await axios.delete(`${API_URL}/api/SendWebhook/${id}`, { headers });

    return response.data;
  } catch (error) {
    console.error("Erro ao deletar webhook:", error);
    throw error;
  }
};

export const getWebhook = async (id) => {
  try {
    const token = Cookies.get("authToken");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await axios.get(`${API_URL}/api/SendWebhook/${id}`, { headers });

    return response.data;
  } catch (error) {
    console.error("Erro ao obter webhook:", error);
    throw error;
  }
};

export const getWebhookByUserId = async (userId) => {
  try {
    const token = Cookies.get("authToken");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await axios.get(`${API_URL}/api/SendWebhook/user/${userId}`, { headers });

    return response.data;
  } catch (error) {
    console.error("Erro ao obter webhook por userId:", error);
    throw error;
  }
};
