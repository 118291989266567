import React, {useState, useEffect} from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import InfosOverviewCard from "components/DashboardComponents/InfosOverviewCard/InfosOverviewCard";
import ConversionIndexCard from "components/DashboardComponents/ConversionIndexCard/ConversionIndexCard";
import DateRangePicker from "components/DashboardComponents/DayPicker/DayPicker";
import ModalBalance from "components/DashboardComponents/ModalBalance/ModalBalance";
import LineChart from "components/LineChart/LineChart";
import SimpleTable from "components/DashboardComponents/SimpleTable/SimpleTable";
import {ReactComponent as MoneyIcon} from "assets/icons/cash-coin.svg";
import {ReactComponent as PixIcon} from "assets/icons/pix-icon.svg";
import {ReactComponent as CardIcon} from "assets/icons/card-icon.svg";
import {ReactComponent as WalletIcon} from "assets/icons/wallet2.svg";
import {ReactComponent as CartIcon} from "assets/icons/cart-check.svg";
import {ReactComponent as CashIcon} from "assets/icons/cash.svg";
import {
  getTransactions,
  getUserBalance,
  getUserKycData,
} from "services/authService";
import "./MerchantDashboard.css";

function MerchantDashboard({onDateRangeChange}) {
  const [balanceData, setBalanceData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionData, setTransactionData] = useState({items: []});
  const [merchantName, setMerchantName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState({
    from: new Date(new Date().setDate(new Date().getDate() - 7)),
    to: new Date(),
  });
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        const data = await getUserKycData(userId);
        const firstName = data.fullName.split(" ")[0];
        setMerchantName(firstName);
      } catch (error) {
        console.error("Erro ao buscar os dados do merchant:", error);
      }
    };

    if (userId) {
      fetchMerchantData();
    }
  }, [userId]);

  const handleDateRangeChange = async (range) => {
    setSelectedRange(range);

    if (onDateRangeChange) {
      onDateRangeChange(range);
    }

    if (!range?.from || !range?.to) return;

    const startDate = new Date(range.from);
    const endDate = new Date(range.to);
    endDate.setHours(23, 59, 59, 999);

    try {
      setLoading(true);
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID não encontrado.");
      }

      let allTransactions = [];
      let currentPage = 1;
      let moreData = true;

      while (moreData) {
        const data = await getTransactions({
          userId: Number(userId),
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          page: currentPage,
          pageSize: 10,
        });

        allTransactions = [...allTransactions, ...data.items];
        currentPage++;
        moreData = data.items.length > 0;
      }

      setTransactionData({items: allTransactions});
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        setLoading(true);
        if (!userId) {
          throw new Error("User ID não encontrado.");
        }

        const balance = await getUserBalance(userId);
        setBalanceData(balance);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBalance();
  }, []);

  useEffect(() => {
    if (selectedRange) {
      handleDateRangeChange(selectedRange);
    }
  }, [selectedRange]);

  const successfulTransactions =
    transactionData?.items.filter((item) => {
      const createdAt = new Date(item.createdAt);
      return (
        item.enStatusTransaction === "Successful" &&
        createdAt >= new Date(selectedRange.from) &&
        createdAt <= new Date(selectedRange.to).setHours(23, 59, 59, 999)
      );
    }) || [];

  const totalSales = {
    pix:
      successfulTransactions
        .filter((item) => item.enPaymentSystem === "Pix")
        .reduce((sum, item) => sum + item.value, 0) || 0,

    creditCard:
      successfulTransactions
        .filter((item) => item.enPaymentSystem === "Credit")
        .reduce((sum, item) => sum + item.value, 0) || 0,

    boleto:
      successfulTransactions
        .filter((item) => item.enPaymentSystem === "Ted")
        .reduce((sum, item) => sum + item.value, 0) || 0,
  };

  const totalOrdersPix =
    successfulTransactions.filter((item) => item.enPaymentSystem === "Pix")
      .length || 0;

  const totalOrdersCredit =
    successfulTransactions.filter((item) => item.enPaymentSystem === "Credit")
      .length || 0;

  const totalOrdersTed =
    successfulTransactions.filter((item) => item.enPaymentSystem === "Ted")
      .length || 0;

  const ticketAverage = {
    pix: totalOrdersPix ? totalSales.pix / totalOrdersPix : 0,
    creditCard: totalOrdersCredit
      ? totalSales.creditCard / totalOrdersCredit
      : 0,
    boleto: totalOrdersTed ? totalSales.boleto / totalOrdersTed : 0,
    total: successfulTransactions.length
      ? (totalSales.pix + totalSales.creditCard + totalSales.boleto) /
      successfulTransactions.length
      : 0,
  };

  if (loading) {
    return <div className="loading-spinner">Carregando...</div>;
  }

  if (error) {
    return <p>Erro ao carregar dados: {error}</p>;
  }

  const releasedBalance = {
    pix: balanceData?.accBalance || 0,
    creditCard: balanceData?.accBalanceCredit || 0,
    boleto: 0,
  };

  const quantityData1 = {
    pix:
      successfulTransactions.filter((item) => item.enPaymentSystem === "Pix")
        .length || 0,
    creditCard:
      successfulTransactions.filter((item) => item.enPaymentSystem === "Credit")
        .length || 0,
    boleto:
      successfulTransactions.filter((item) => item.enPaymentSystem === "Ted")
        .length || 0,
  };

  const timeLabels = [
    ...new Set(
      successfulTransactions.map((item) =>
        new Date(item.createdAt).toLocaleDateString("pt-BR")
      )
    ),
  ].sort((a, b) => {
    const dateA = new Date(a.split("/").reverse().join("-"));
    const dateB = new Date(b.split("/").reverse().join("-"));
    return dateA - dateB;
  });

  const hubData = [
    {
      label: "Pix",
      data: timeLabels.map((date) =>
        successfulTransactions
          .filter(
            (item) =>
              item.enPaymentSystem === "Pix" &&
              new Date(item.createdAt).toLocaleDateString("pt-BR") === date
          )
          .reduce((sum, item) => sum + item.value, 0)
      ),
      color: "#93BECE",
    },
    {
      label: "Cartão de Crédito",
      data: timeLabels.map((date) =>
        successfulTransactions
          .filter(
            (item) =>
              item.enPaymentSystem === "Credit" &&
              new Date(item.createdAt).toLocaleDateString("pt-BR") === date
          )
          .reduce((sum, item) => sum + item.value, 0)
      ),
      color: "#fff",
    },
    {
      label: "Boleto",
      data: timeLabels.map((date) =>
        successfulTransactions
          .filter(
            (item) =>
              item.enPaymentSystem === "Ted" &&
              new Date(item.createdAt).toLocaleDateString("pt-BR") === date
          )
          .reduce((sum, item) => sum + item.value, 0)
      ),
      color: "#000",
    },
  ];

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const tableColumns = [
    // {field: "id", header: "ID"},
    {field: "enPaymentSystem", header: "Método de Pagamento"},
    {
      field: "value",
      header: "Valor",
      body: (rowData) =>
        (rowData.value).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
    },
  ];

  const getStatusText = (status) => {
    switch (status) {
      case "Successful":
        return "Aprovada";
      case "Awaiting":
        return "Em Análise";
      case "Failure":
        return "Reprovada";
      default:
        return "Indefinido";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Successful":
        return "#1eff00";
      case "Awaiting":
        return "#fdc313";
      case "Failure":
        return "#f44336";
      default:
        return "#6c757d";
    }
  };

  const totalByPaymentSystem = {
    pix: transactionData.items.filter(
      (item) => item.enPaymentSystem === "Pix"
    ).length,
    credit: transactionData.items.filter(
      (item) => item.enPaymentSystem === "Credit"
    ).length,
    boleto: transactionData.items.filter(
      (item) => item.enPaymentSystem === "Ted"
    ).length,
  };

  const successfulByPaymentSystem = {
    pix: successfulTransactions.filter(
      (item) => item.enPaymentSystem === "Pix"
    ).length,
    credit: successfulTransactions.filter(
      (item) => item.enPaymentSystem === "Credit"
    ).length,
    boleto: successfulTransactions.filter(
      (item) => item.enPaymentSystem === "Ted"
    ).length,
  };

  const conversionPercentages = {
    pix: totalByPaymentSystem.pix
      ? ((successfulByPaymentSystem.pix / totalByPaymentSystem.pix) * 100).toFixed(1)
      : 0,
    credit: totalByPaymentSystem.credit
      ? ((successfulByPaymentSystem.credit / totalByPaymentSystem.credit) * 100).toFixed(1)
      : 0,
    boleto: totalByPaymentSystem.boleto
      ? ((successfulByPaymentSystem.boleto / totalByPaymentSystem.boleto) * 100).toFixed(1)
      : 0,
  };

  const conversionData = [
    {
      title: "PIX",
      percentage: conversionPercentages.pix,
      description: "Conversão",
      color: "var(--text-color)",
      helperText: `${totalByPaymentSystem.pix} transações`,
    },
    {
      title: "Cartão de Crédito",
      percentage: conversionPercentages.credit,
      description: "Conversão",
      color: "var(--text-color)",
      helperText: `${totalByPaymentSystem.credit} transações`,
    },
    {
      title: "Boleto",
      percentage: conversionPercentages.boleto,
      description: "Conversão",
      color: "var(--text-color)",
      helperText: `${totalByPaymentSystem.boleto} transações`,
    },
    {
      title: "Chargeback",
      percentage: conversionPercentages.boleto,
      description: "Porcentagem de Chargeback",
      color: "#D32F2F",
      helperText: `${totalByPaymentSystem.boleto} transações`,
    },
  ];

  return (
    <div className="home-dashboard">
      <div className="merchant-grids">
        <div className="merchant-content-1">
          <div className="merchant-grid-1">
            <div className="dash-information">
              <div className="welcome-content">
                <h1 className="dash-title dash-title-home">
                  Bem-vindo, {merchantName || "Carregando..."}!
                </h1>
                <p className="dash-info">
                  Aqui você pode encontrar uma visão geral sobre suas vendas.
                </p>
              </div>

              <DateRangePicker
                selectedRange={selectedRange}
                setSelectedRange={setSelectedRange}
              />
            </div>
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Total de Vendas"
                  salesData={
                    totalSales.pix + totalSales.boleto + totalSales.creditCard
                  }
                  salesColor="black"
                  totalSoldText=" "
                  icon={<MoneyIcon />}
                />
                <SalesOverviewCard
                  title="PIX e Boleto"
                  salesData={totalSales.pix + totalSales.boleto}
                  salesColor="black"
                  totalSoldText=" "
                  salesMethodText=" "
                  icon={<PixIcon />}
                />
                <SalesOverviewCard
                  title="Cartão de Crédito"
                  salesData={totalSales.creditCard}
                  salesColor="black"
                  totalSoldText=" "
                  salesMethodText=" "
                  icon={<CardIcon />}
                />
                <SalesOverviewCard
                  title="Saldo"
                  salesData={
                    (balanceData?.accBalance || 0) +
                    (balanceData?.accBalanceCredit || 0)
                  }
                  salesColor="black"
                  totalSoldText=" "
                  icon={<WalletIcon />}
                />
              </div>
            </div>
            <div className="charts-section">
              <div className="information-cards">
                <InfosOverviewCard
                  title="Total de Pedidos"
                  salesData={quantityData1}
                  salesColor="black"
                  totalSoldText=" "
                  salesMethodText=" "
                  icon={<CartIcon />}
                />
                <SalesOverviewCard
                  title="Ticket Médio"
                  salesData={ticketAverage.total}
                  salesColor="black"
                  totalSoldText=" "
                  salesMethodText=" "
                  icon={<CashIcon />}
                />
              </div>

              <div className="line-charts1">
                <LineChart
                  title="Histórico de Vendas"
                  timeLabels={timeLabels}
                  hubData={hubData}
                />
              </div>
            </div>
          </div>
          <div className="merchant-grid-2">
            <div className="dash-information dash-information-2" style={{marginLeft: "0", alignItems: "flex-start"}}>
              <h1 className="dash-title dash-title-home">Transações</h1>
              <p className="dash-info">Últimas vendas.</p>
            </div>
            <div className="index-circular">
              <SimpleTable
                data={transactionData.items.slice(-5)}
                columns={tableColumns}
                showStatusColumn={true}
                statusField="enStatusTransaction"
                getStatusText={getStatusText}
                getStatusColor={getStatusColor}
              />
            </div>
            <div className="index-circular">
              {conversionData.map((item, index) => (
                <ConversionIndexCard
                  key={index}
                  title={item.title}
                  percentage={item.percentage}
                  description={item.description}
                  color={item.color}
                  helperText={item.helperText}
                />
              ))}
            </div>

          </div>
        </div>
      </div>
      <ModalBalance
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
        saldoPix={releasedBalance.pix}
        saldoCartao={releasedBalance.creditCard}
        userId={userId}
      />
    </div>
  );
}

export default MerchantDashboard;
