import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { ptBR } from "react-day-picker/locale";
import 'react-day-picker/dist/style.css';
import './DayPicker.css';

const DateRangePicker = ({ selectedRange, setSelectedRange }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [tempRange, setTempRange] = useState(null);

  const predefinidos = [
    { label: 'Hoje', value: 1 },
    { label: 'Ontem', value: 2 },
    { label: '7 dias', value: 7 },
    { label: '15 dias', value: 15 },
    { label: '30 dias', value: 30 }
  ];

  const formatDate = (date) => {
    if (!date) return '';
    return new Intl.DateTimeFormat('pt-BR').format(date); 
  };

  const handlePredefinidoClick = (days) => {
    const hoje = new Date();
    hoje.setHours(23, 59, 59, 999); // Fim do dia atual
    const startDate = new Date();
    startDate.setDate(hoje.getDate() - days);
    startDate.setHours(0, 0, 0, 0); // Início do dia inicial

    setSelectedRange({
      from: startDate,
      to: hoje,
    });
    setShowDropdown(false);
  };

  const handleCustomRangeClick = () => {
    setShowCalendar(true);
    setShowDropdown(false);
  };

  const handleDateSelect = (range) => {
    if (range?.from && range?.to) {
      range = {
        from: new Date(range.from.getFullYear(), range.from.getMonth(), range.from.getDate(), 0, 0, 0, 0), // Início do dia
        to: new Date(range.to.getFullYear(), range.to.getMonth(), range.to.getDate(), 23, 59, 59, 999),   // Fim do dia
      };
    }
    setTempRange(range);
  };

  const handleApply = () => {
    if (tempRange && tempRange.from && tempRange.to) {
      setSelectedRange(tempRange);
    }
    setShowCalendar(false);
  };

  const handleClear = () => {
    const hoje = new Date();
    hoje.setHours(23, 59, 59, 999);
    const startDate = new Date();
    startDate.setDate(hoje.getDate() - 7);
    startDate.setHours(0, 0, 0, 0);

    setSelectedRange({
      from: startDate,
      to: hoje,
    });
    setTempRange(null);
    setShowCalendar(false);
  };

  const toggleCalendarOrDropdown = () => {
    if (showCalendar) {
      setShowCalendar(false);
    } else {
      setShowDropdown(!showDropdown);
    }
  };

  return (
    <div className="date-picker-container" style={{ display: 'flex', height: '35px' }}>
      <div
        className="selected-range"
        onClick={toggleCalendarOrDropdown}
        style={{
          fontFamily: '"Poppins", sans-serif',
          fontSize: '14px',
          padding: '0.6rem',
          borderRadius: '1rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          minWidth: '160px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {selectedRange && selectedRange.from && selectedRange.to
          ? `${formatDate(selectedRange.from)} - ${formatDate(selectedRange.to)}`
          : 'Período'}
      </div>

      {showDropdown && !showCalendar && (
        <div className="dropdown-menu">
          {predefinidos.map((item) => (
            <div
              key={item.value}
              className="dropdown-item"
              onClick={() => handlePredefinidoClick(item.value)}
            >
              {item.label}
            </div>
          ))}
          <div className="dropdown-item" onClick={handleCustomRangeClick}>
            Período personalizado
          </div>
        </div>
      )}
      {showCalendar && (
        <div className="calendar-container">
          <DayPicker
            mode="range"
            selected={tempRange}
            onSelect={handleDateSelect}
            numberOfMonths={2}
            timeZone="America/Sao_Paulo"
            locale={ptBR}
          />
          <div className="calendar-footer">
            <button className="clear-button" onClick={handleClear}>Limpar</button>
            <button className="apply-button" onClick={handleApply}>Aplicar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
