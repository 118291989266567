import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function LineChart({ title, timeLabels, hubData }) {
  const formattedData = timeLabels.map((label, index) => {
    const entry = { time: label };
    hubData.forEach((hub) => {
      entry[hub.label] = hub.data[index];
    });
    return entry;
  });

  return (
    <div className="line-chart" style={{ padding: '20px', maxWidth: '100%' }}>
      <h2 style={{ textAlign: 'center', fontSize: '20px' }}>{title || 'Vendas por mês'}</h2>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          data={formattedData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            {hubData.map((hub, index) => (
              <linearGradient key={index} id={`colorGradient${index}`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={hub.color} stopOpacity={0.8} />
                <stop offset="95%" stopColor={hub.color} stopOpacity={0} />
              </linearGradient>
            ))}
          </defs>

          <XAxis dataKey="time" tick={{ fontSize: 12 }} stroke="gray" />
          <YAxis tick={{ fontSize: 12 }} />
          <Tooltip />
          <Legend wrapperStyle={{ fontSize: '14px', color: '#666' }} />

          {hubData.map((hub, index) => (
            <Area
              key={hub.label}
              type="monotone"
              dataKey={hub.label}
              stroke={hub.color}
              fillOpacity={1}
              fill={`url(#colorGradient${index})`}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default LineChart;
