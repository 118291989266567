import React, { useState } from "react";
import Modal from "react-modal";
import { requestCashOut } from "services/authService";
import "./ModalBalance.css";

Modal.setAppElement("#root");

const ModalBalance = ({ isOpen, closeModal, saldoDisponivel, userId }) => {
  const [valorSaque, setValorSaque] = useState("R$ 0,00");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formatToBRL = (value) => {
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value || 0);
    return formattedValue;
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");
    const floatValue = parseFloat(value) / 100;
    setValorSaque(formatToBRL(floatValue));
  };

  const handleSaque = async () => {
    const amount = parseFloat(
      valorSaque
        .replace(/[^\d,]/g, "")
        .replace(".", "")
        .replace(",", ".")
    );

    if (!userId) {
      setError("Usuário não identificado para solicitar saque.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await requestCashOut(userId, amount);

      alert(`Saque de ${formatToBRL(amount)} solicitado com sucesso!`);
      closeModal();
      window.location.reload();
    } catch (err) {
      console.error(err);
      setError(err.message || "Erro ao solicitar saque. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = valorSaque === "R$ 0,00" || valorSaque === "";

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <div className="modal-header">
        <h2 style={{textAlign: "center"}}>Solicitar saque</h2>
      </div>
      <h3 style={{fontWeight: "400", textAlign: "center"}}>Taxa de Saque: <strong>R$ 10,00</strong></h3>

      <div className="modal-body">
        <div className="saque-section">
          <p>Saldo disponível: <strong>{formatToBRL(saldoDisponivel)}</strong></p>
          <input
            type="text"
            placeholder="Digite o valor do saque"
            value={valorSaque}
            onChange={handleInputChange}
          />
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>

      <div className="modal-footer">
        <button onClick={closeModal} className="cancel-btn">
          Cancelar
        </button>
        <button
          onClick={handleSaque}
          className="sacar-btn"
          disabled={isDisabled || loading}
        >
          {loading ? "Solicitando..." : "Solicitar"}
        </button>
      </div>
    </Modal>
  );
};

export default ModalBalance;
