import React, { useState, useEffect, useCallback } from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import ModalBalance from "components/DashboardComponents/ModalBalance/ModalBalance";
import { ReactComponent as TimeIcon } from "assets/icons/stopwatch.svg";
import { ReactComponent as CardIcon } from "assets/icons/card-icon.svg";
import { ReactComponent as PixIcon } from "assets/icons/pix-icon.svg";
import {
  getUserBalance,
  getUserSummary,
  getWithdrawals,
} from "services/authService";
import "./MerchantBalance.css";

function MerchantBalance() {
  const [balanceData, setBalanceData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const userId = localStorage.getItem("userId");

  const fetchWithdrawals = useCallback(
    async (page, pageSize, searchTerm = "") => {
      setLoading(true);
      try {
        const result = await getWithdrawals({
          UserId: userId,
          page: page + 1,
          pageSize,
          Term: searchTerm,
        });

        if (result && result.items && result.totalCount !== undefined) {
          setTableData(
            result.items.map((withdrawal) => ({
              value: withdrawal.value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              date: new Date(withdrawal.createdAt).toLocaleDateString("pt-BR"),
              status: withdrawal.enTransaction,
              method: withdrawal.enPaymentSystem,
            }))
          );
          setTotalRecords(result.totalCount);
        } else {
          console.error("Formato inesperado da resposta da API:", result);
        }
      } catch (error) {
        console.error("Erro ao buscar histórico de saques:", error);
      } finally {
        setLoading(false);
      }
    },
    [userId]
  );

  useEffect(() => {
    fetchWithdrawals(page, pageSize, searchTerm);
  }, [page, pageSize, searchTerm, fetchWithdrawals]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (!userId) {
          throw new Error("User ID não encontrado.");
        }

        const [summary, balance] = await Promise.all([
          getUserSummary(userId),
          getUserBalance(userId),
        ]);

        setSummaryData(summary);
        setBalanceData(balance);
      } catch (error) {
        console.error("Erro ao buscar dados de resumo e saldo:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const releasedBalance = {
    pix: balanceData?.accBalance || 0,
    creditCard: balanceData?.accBalanceCredit || 0,
    boleto: balanceData?.accBalanceBoleto || 0,
  };

  const awaitingBalance = {
    pix: balanceData?.accBalanceAwaiting || 0,
    creditCard: balanceData?.accBalanceCreditAwaiting || 0,
    boleto: balanceData?.accBalanceBoletoAwaiting || 0,
  };

  const columns = [
    { field: "value", header: "Valor" },
    { field: "date", header: "Data" },
    { field: "method", header: "Método de Pagamento" },
  ];

  const statusOptions = [
    { label: "Aguardando", value: "Awaiting" },
    { label: "Pendente", value: "Pending" },
    { label: "Aprovado", value: "Successful" },
    { label: "Rejeitado", value: "Failure" },
    { label: "Chargeback", value: "Chargeback" },
    { label: "Estornado", value: "Refunded" },
    { label: "Pré-Chargeback", value: "PreChargeback" },
  ];

  const getStatusText = (status) => {
    switch (status) {
      case "Successful":
        return "Aprovado";
      case "Awaiting":
        return "Aguardando";
      case "Failure":
        return "Reprovado";
      case "Pending":
        return "Pendente";
      case "PreChargeback":
        return "Pré-Chargeback";
      case "Chargeback":
        return "Chargeback";
      case "Refunded":
        return "Estornado";
      default:
        return "Indefinido";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Successful":
        return "#1eff00";
      case "Awaiting":
        return "#fdc313";
      case "Failure":
        return "#f44336";
      case "Pending":
        return "#fdc313";
      case "PreChargeback":
        return "#f44336";
      case "Chargeback":
        return "#f44336";
      case "Refunded":
        return "#f44336";
      default:
        return "#6c757d";
    }
  };

  const onPageChange = (event) => {
    setPage(event.page);
    setPageSize(event.rows);
  };

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  const handleOpenModal = (balance) => {
    setCurrentBalance(balance);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchWithdrawals(page, pageSize, searchTerm);
  };

  return (
    <div className="home-dashboard">
      <div className="dash-container">
        <h1 className="dash-title">Transferências</h1>
      </div>
      <div className="merchant-grids">
        <div className="merchant-content-1">
          <div className="merchant-grid-1" style={{width: "100%"}}>
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Saldo disponível"
                  salesData={releasedBalance.pix + releasedBalance.boleto}
                  salesColor="black"
                  totalSoldText=" "
                  salesMethodText="Métodos de pagamento selecionados"
                  icon={<PixIcon />}
                  showDotButton={true}
                  onDotButtonClick={() =>
                    handleOpenModal(
                      releasedBalance.pix + releasedBalance.boleto
                    )
                  }
                />
                <SalesOverviewCard
                  title="Saldo disponível"
                  salesData={releasedBalance.creditCard}
                  salesColor="black"
                  totalSoldText=" "
                  salesMethodText="Métodos de pagamento selecionados"
                  icon={<CardIcon />}
                  showDotButton={true}
                  onDotButtonClick={() =>
                    handleOpenModal(releasedBalance.creditCard)
                  }
                />
                <SalesOverviewCard
                  title="Saldo Pendente"
                  salesData={
                    awaitingBalance.pix +
                    awaitingBalance.creditCard +
                    awaitingBalance.boleto
                  }
                  salesColor="black"
                  totalSoldText=" "
                  salesMethodText="Selecione para ver total líquido por método de pagamento"
                  icon={<TimeIcon />}
                />
              </div>
            </div>
            <div className="table-section">
              <CustomDataTable
                data={tableData}
                columns={columns}
                title="Histórico de saques"
                loading={loading}
                totalRecords={totalRecords}
                rows={pageSize}
                page={page}
                onPageChange={onPageChange}
                onSearch={onSearch}
                searchPlaceholder="Buscar por valor, data ou status"
                showStatusColumn={true}
                statusField="status"
                getStatusText={getStatusText}
                getStatusColor={getStatusColor}
                statusOptions={statusOptions}
              />
            </div>
          </div>
          {/* <div className="merchant-grid-2">
            <SalesStatistics
              title="Chargebacks, Reembolsos e Cancelamentos"
              salesData={salesData}
              colors={customColors}
            />
          </div> */}
        </div>
      </div>
      <ModalBalance
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
        saldoDisponivel={currentBalance}
        userId={userId}
      />
    </div>
  );
}

export default MerchantBalance;
