import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Incluindo useParams para pegar o userId
import PrimaryButton from "components/DashboardComponents/PrimaryButton/PrimaryButton";
import DashboardLayout from "layouts/DashboardLayout/DashBoardLayout";
import AdminMerchantDashboard from "components/AdminControl/AdminMerchantDashboard/AdminMerchantDashboard";
import AdminMerchantTransactions from "components/AdminControl/AdminMerchantTransactions/AdminMerchantTransactions";
import AdminMerchantLeads from "components/AdminControl/AdminMerchantLeads/AdminMerchantLeads";
import AdminMerchantBalance from "components/AdminControl/AdminMerchantBalance/AdminMerchantBalance";
import AdminMerchantTax from "components/AdminControl/AdminMerchantTax/AdminMerchantTax";
import AdminMerchantDetails from "components/AdminControl/AdminMerchantDetails/AdminMerchantDetails";
import { getUserKycData } from "services/authService"; // Importando o serviço para buscar os dados do KYC
import "./MerchantDetails.css";

function MerchantDetails() {
  const [activeComponent, setActiveComponent] = useState("details");
  const [activeSecondIcon, setActiveSecondIcon] = useState(null);
  const [activeIcon, setActiveIcon] = useState(0);
  const [merchantName, setMerchantName] = useState("");
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        const data = await getUserKycData(userId);
        setMerchantName(data.fullName);
      } catch (error) {
        console.error("Erro ao buscar os dados do merchant:", error);
      }
    };

    if (userId) {
      fetchMerchantData();
    }
  }, [userId]);

  const firstSideBarIcons = [
    { iconClass: "pi-user", label: "Dados", component: "details" },
    { iconClass: "pi-home", label: "Dashboard", component: "home" },
    { iconClass: "pi-shopping-bag", label: "Vendas", component: "sales" },
    { iconClass: "pi-money-bill", label: "Saques", component: "transfers" },
  ];

  const secondSideBarIcons = [
    [], // Sem submenu para Home
    [], // Sem submenu para Shopping
    [], // Sem submenu para User
    [], // Submenu para Money Bill
  ];

  // Ícones da TopBar com seus respectivos componentes
  const topBarIcons = [];

  // Função para lidar com cliques nos ícones da TopBar
  const handleTopBarIconClick = (component) => {
    setActiveComponent(component); // Define o componente ativo com base no ícone clicado
  };

  // Função para realizar o logout e redirecionar para a página /admin
  const handleLogoutMerchant = () => {
    if (window.confirm("Você realmente deseja deslogar?")) {
      navigate("/admin"); // Redireciona para a página de admin após o logout
    }
  };

  const handleFirstIconClick = (iconIndex) => {
    const icon = firstSideBarIcons[iconIndex];
    setActiveIcon(iconIndex);
    setActiveSecondIcon(null);

    if (icon.component) {
      setActiveComponent(icon.component);
    }
  };

  const handleSecondIconClick = (iconIndex) => {
    const icon = secondSideBarIcons[iconIndex];
    setActiveSecondIcon(iconIndex);
    setActiveIcon(null);

    if (icon.action) {
      icon.action();
    } else if (icon.component) {
      setActiveComponent(icon.component);
    }
  };

  return (
    <div>
      <div className="merchant-content">
        <DashboardLayout
          firstSideBarIcons={firstSideBarIcons}
          secondSideBarMenus={secondSideBarIcons}
          onFirstIconClick={handleFirstIconClick}
          onSecondIconClick={handleSecondIconClick}
          activeIcon={activeIcon}
          topBarIcons={topBarIcons}
          onTopBarIconClick={handleTopBarIconClick}
        >
          <div className="dash-container">
            <h1 className="dash-title">
              {/* Exibe o nome do Merchant */}
              Você está logado como {merchantName || "Carregando..."}
            </h1>
            <PrimaryButton
              icon="pi pi-sign-out"
              label="Deslogar"
              onClick={handleLogoutMerchant}
              className=""
              style={{
                margin: "2px",
                height: "40px",
              }}
            />
          </div>

          {/* Renderização condicional baseada no estado activeComponent */}
          {activeComponent === "home" && <AdminMerchantDashboard />}
          {activeComponent === "sales" && <AdminMerchantTransactions />}
          {activeComponent === "leads" && <AdminMerchantLeads />}
          {activeComponent === "transfers" && <AdminMerchantBalance />}
          {activeComponent === "tax" && <AdminMerchantTax />}
          {activeComponent === "details" && <AdminMerchantDetails />}
          {activeComponent === "settings" && <div>Configurações</div>}
        </DashboardLayout>
      </div>
    </div>
  );
}

export default MerchantDetails;
