import React, { useState, useEffect } from "react";
import SideBar from "components/SideBar/SideBar";
import "./DashboardLayout.css";

const DashboardLayout = ({
  firstSideBarIcons, // Ícones da parte superior da Sidebar
  secondSideBarIcons, // Ícones da parte inferior da Sidebar
  onFirstIconClick, // Função para cliques nos ícones superiores
  onSecondIconClick, // Função para cliques nos ícones inferiores
  activeIcon, // Ícone ativo da primeira lista
  activeSecondIcon,
  children,
}) => {
  const [isExpanded] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Inicializa o tema com base no localStorage
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    const isDark = savedTheme === "dark";
    setIsDarkMode(isDark);

    // Aplica a classe de tema ao body
    if (isDark) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  }, []);

  const handleThemeChange = (isDark) => {
    setIsDarkMode(isDark);

    // Atualiza o localStorage e a classe do body
    const newTheme = isDark ? "dark" : "light";
    localStorage.setItem("theme", newTheme);

    if (isDark) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  };

  return (
    <div className="dashboard-layout">
      <div className={`sidebars-container ${isExpanded ? "expanded" : "collapsed"}`}>
        <SideBar
          firstSideBarIcons={firstSideBarIcons}
          secondSideBarIcons={secondSideBarIcons}
          isExpanded={isExpanded}
          onFirstIconClick={onFirstIconClick}
          onSecondIconClick={onSecondIconClick}
          activeIcon={activeIcon}
          activeSecondIcon={activeSecondIcon}
          onThemeChange={handleThemeChange} // Passa a função de tema
          isDarkMode={isDarkMode} // Passa o estado do tema
        />
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default DashboardLayout;
