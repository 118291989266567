import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import StatusLabel from "components/StatusLabel/StatusLabel"; // Reutilizando o StatusLabel
import PrimaryButton from "components/DashboardComponents/PrimaryButton/PrimaryButton";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./SimpleTable.css";

const SimpleTable = ({
  data,
  columns,
  title,
  showStatusColumn,
  statusField,
  getStatusText,
  getStatusColor,
  showActionColumn = false,
  actionButtonLabel,
  actionButtonIcon,
  onActionButtonClick,
}) => {
  // Template para a coluna de status
  const statusColumnBodyTemplate = (rowData) => {
    const statusText = getStatusText(rowData[statusField]);
    const statusColor = getStatusColor
      ? getStatusColor(rowData[statusField])
      : "#6C757D";
    return <StatusLabel text={statusText} color={statusColor} />;
  };

  // Template para a coluna de ações
  const actionColumnBodyTemplate = (rowData) => {
    return (
      <PrimaryButton
        label={actionButtonLabel || "Action"}
        icon={actionButtonIcon || "pi pi-cog"}
        onClick={() => onActionButtonClick(rowData)}
        style={{ margin: "0 auto" }}
      />
    );
  };

  return (
    <div
      className="simple-table-wrapper"
      style={{
        width: "100%",
        margin: "10px",
        borderRadius: "24px",
      }}
    >
      <h2 className="simple-table-title">{title}</h2>
      <DataTable
        value={data}
        className="simple-data-table"
        tableStyle={{
          minWidth: "50%",
          width: "100%",
        }}
      >
        {columns.map((col, index) => (
          <Column
            key={index}
            field={col.field}
            header={col.header}
            bodyClassName={`column-body-${col.field}`}
            headerClassName={`column-header-${col.field}`}
            body={col.body}
            tableStyle={{
              backgroundColor: "transparent"
            }}
          />
        ))}

        {showStatusColumn && (
          <Column
            header="Status"
            body={statusColumnBodyTemplate}
            className="column-status"
            style={{ 
              textAlign: "center" }}
          />
        )}

        {showActionColumn && (
          <Column
            header="Ação"
            body={actionColumnBodyTemplate}
            className="column-action"
            style={{ textAlign: "start" }}
          />
        )}
      </DataTable>
    </div>
  );
};

export default SimpleTable;
