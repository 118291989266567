import React, { useEffect, useState } from "react";
import { getDistributionControl, updateDistributionControl } from "services/acquirerService";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import "./AdminAcquirer.css";

const AdminAcquirer = () => {
  const [acquirers, setAcquirers] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDistributionControl();
        setAcquirers(data);
        groupByPaymentMethod(data);
      } catch (error) {
        console.error("Erro ao buscar dados: ", error);
      }
    };

    fetchData();
  }, []);

  const groupByPaymentMethod = (data) => {
    const grouped = data.reduce((acc, item) => {
      const { paymentMethod } = item;
      if (!acc[paymentMethod]) acc[paymentMethod] = [];
      acc[paymentMethod].push(item);
      return acc;
    }, {});
    setGroupedData(grouped);
  };

  const handlePercentageChange = (paymentMethod, id, newPercentage) => {
    setGroupedData((prev) => {
      const totalPercentage = prev[paymentMethod]?.reduce(
        (sum, item) => sum + (item.id === id ? newPercentage : item.percentage),
        0
      );

      if (totalPercentage > 100) {
        return prev;
      }

      const updatedGroup = prev[paymentMethod].map((item) => {
        if (item.id === id) {
          return { ...item, percentage: Math.min(newPercentage, 100) };
        }
        return item;
      });

      return { ...prev, [paymentMethod]: updatedGroup };
    });
  };

  const calculateRemainingPercentage = (paymentMethod) => {
    const totalPercentage =
      groupedData[paymentMethod]?.reduce(
        (sum, item) => sum + item.percentage,
        0
      ) || 0;
    return 100 - totalPercentage;
  };

  const formatPaymentMethod = (method) => {
    const mapping = {
      credit_card: "Cartão de Crédito",
      pix: "Pix",
      boleto: "Boleto",
    };
    return mapping[method] || method;
  };

  const handleSave = async (paymentMethod) => {
    setIsSaving(true);
    const updates = groupedData[paymentMethod];
  
    try {
      for (const acquirer of updates) {
        const payload = {
          id: acquirer.id,
          paymentMethod,
          integrationName: acquirer.integrationName,
          percentage: acquirer.percentage,
          isActive: true,
        };
        console.log("Enviando payload:", payload);
        await updateDistributionControl(payload);
      }
      alert(`Alterações salvas para ${paymentMethod}`);
      console.log(`Alterações salvas para ${paymentMethod}`);
    } catch (error) {
      console.error(`Erro ao salvar alterações para ${paymentMethod}:`, error);
    } finally {
      setIsSaving(false);
    }
  };

  const renderInputs = () => {
    return (
      <div className="payment-method-container">
        {Object.keys(groupedData).map((method) => (
          <div key={method} className="payment-method-section">
            <h2>{formatPaymentMethod(method)}</h2>
            {groupedData[method].map((acquirer, index) => (
              <div key={acquirer.id} className="acquirer-row">
                <span>
                  {index + 1}. {acquirer.integrationName}
                </span>
                <input
                  type="number"
                  value={acquirer.percentage}
                  onChange={(e) =>
                    handlePercentageChange(
                      method,
                      acquirer.id,
                      Number(e.target.value)
                    )
                  }
                  className="percentage-input"
                  min="0"
                  max="100"
                />
              </div>
            ))}
            <p className="remaining-percentage">
              Porcentagem Faltante: {calculateRemainingPercentage(method)}%
            </p>
            <PrimaryButton
              label={isSaving ? "Salvando..." : "Salvar"}
              icon="pi pi-save"
              onClick={() => handleSave(method)}
              className="save-btn"
              style={{ marginTop: "10px" }}
              disabled={isSaving}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="merchant-tax-container">
      <h1 className="dash-title">Controle de Adquirentes</h1>
      {renderInputs()}
    </div>
  );
};

export default AdminAcquirer;
