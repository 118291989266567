import React, { useState } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import PrimaryButton from "components/DashboardComponents/PrimaryButton/PrimaryButton";
import logo from "assets/logo-horizontal.png";

const LoginComponent = ({
  email,
  setEmail,
  password,
  setPassword,
  handleLogin,
  loginError,
  errors,
  onSwitchToRegister,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="login-section">
      <div style={{ textAlign: "center" }}>
        <img src={logo} style={{ width: "70%" }} alt="Logo" />
      </div>

      <h1 style={{ textAlign: "center", color: "#000000" }}>Bem vindo!</h1>
      <p>Insira seu email/usuário e senha para acessar.</p>

      <CustomInput
        label="Email ou usuário"
        type="email"
        value={email}
        placeholder="Email ou usuário"
        onChange={(e) => setEmail(e.target.value)}
        errorMessage={errors.email}
      />

      <CustomInput
        label="Senha"
        type={showPassword ? "text" : "password"} // Alterna entre texto e senha
        value={password}
        placeholder="Sua senha segura muito segura"
        onChange={(e) => setPassword(e.target.value)}
        errorMessage={errors.password}
        rightIcon={showPassword ? "pi pi-eye" : "pi pi-eye-slash"} // Ícones do PrimeIcons
        onRightIconClick={togglePasswordVisibility} // Alterna a visibilidade
      />

      <PrimaryButton
        label="Login"
        icon="pi pi-sign-in" // Ícone de envio do PrimeReact
        onClick={handleLogin}
        className="login-button"
        style={{
          width: "100%",
          margin: "0",
          backgroundColor: "#000000",
          color: "#ffffff",
        }}
      />

      {loginError && <p className="error-message">{loginError}</p>}

      <p>
        Não tem uma conta?{" "}
        <span onClick={onSwitchToRegister} className="register-link">
          Cadastre-se
        </span>
      </p>
    </div>
  );
};

export default LoginComponent;
