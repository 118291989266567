import React, { useState, useEffect, useCallback } from "react";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import { getWithdrawals } from "services/authService";
import "./AdminTransfers.css";

const AdminTransfers = () => {
  const [data, setData] = useState([]); // Dados da tabela
  const [page, setPage] = useState(0); // Página atual
  const [pageSize, setPageSize] = useState(10); // Tamanho da página
  const [totalRecords, setTotalRecords] = useState(0); // Total de registros
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [searchTerm, setSearchTerm] = useState(""); // Termo de busca
  const [selectedStatus, setSelectedStatus] = useState(null);

  // Função para buscar transferências
  const fetchWithdrawals = useCallback(
    async (page, pageSize, searchTerm = "") => {
      setLoading(true);
      try {
        const result = await getWithdrawals({
          page: page + 1, // O backend usa base 1
          pageSize,
          Term: searchTerm,
        });

        if (result && result.items && result.totalCount !== undefined) {
          const processedData = result.items.map((withdrawal) => ({
            ID: withdrawal.id,
            ChavePix: withdrawal.pixKey || "Chave não informada",
            Valor: withdrawal.value
              ? new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(withdrawal.value)
              : "R$ 0,00",
            Status: withdrawal.enTransaction || "N/A",
            DataCriacao: withdrawal.createdAt
              ? new Date(withdrawal.createdAt).toLocaleDateString("pt-BR")
              : "N/A",
            DataAtualizacao: withdrawal.updateDate
              ? new Date(withdrawal.updateDate).toLocaleDateString("pt-BR")
              : "N/A",
          }));

          setData(processedData);
          setTotalRecords(result.totalCount);
        } else {
          console.error("Formato inesperado da resposta da API:", result);
        }
      } catch (error) {
        console.error("Erro ao buscar transferências:", error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    fetchWithdrawals(page, pageSize, searchTerm);
  }, [page, pageSize, searchTerm, fetchWithdrawals]);

  const columns = [
    { field: "ID", header: "ID" },
    { field: "ChavePix", header: "Chave Pix" },
    { field: "Valor", header: "Valor" },
  ];

  const statusOptions = [
    { label: "Aguardando", value: "Awaiting" },
    { label: "Pendente", value: "Pending" },
    { label: "Aprovado", value: "Successful" },
    { label: "Rejeitado", value: "Failure" },
    { label: "Chargeback", value: "Chargeback" },
    { label: "Estornado", value: "Refunded" },
    { label: "Pré-Chargeback", value: "PreChargeback" },
  ];

  const getStatusText = (status) => {
    switch (status) {
      case "Successful":
        return "Aprovado";
      case "Awaiting":
        return "Aguardando";
      case "Failure":
        return "Reprovado";
      case "Pending":
        return "Pendente";
      case "PreChargeback":
        return "Pré-Chargeback";
      case "Chargeback":
        return "Chargeback";
      case "Refunded":
        return "Estornado";
      default:
        return "Indefinido";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Successful":
        return "#1eff00";
      case "Awaiting":
        return "#fdc313";
      case "Failure":
        return "#f44336";
      case "Pending":
        return "#fdc313";
      case "PreChargeback":
        return "#f44336";
      case "Chargeback":
        return "#f44336";
      case "Refunded":
        return "#f44336";
      default:
        return "#6c757d";
    }
  };

  const onPageChange = (event) => {
    setPage(event.page);
    setPageSize(event.rows);
  };

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  // Função chamada ao clicar em "Ver mais"
  const handleInfoButtonClick = (rowData) => {
    alert(`Ver mais sobre a transferência: ${rowData.Transfers}`);
    // No futuro, você pode usar isso para abrir um modal ou navegar para outra página
  };

  const exportFields = [
    { field: "ID", label: "ID" },
    { field: "ChavePix", label: "Chave Pix" },
    { field: "Valor", label: "Valor" },
    { field: "Status", label: "Status" },
    { field: "DataCriacao", label: "Data de Criação" },
    { field: "DataAtualizacao", label: "Data de Atualização" },
    { field: "SistemaPagamento", label: "Sistema de Pagamento" },
    { field: "Descricao", label: "Descrição" },
  ];

  const handleExportFromAPI = async ({ searchTerm }) => {
    try {
      const result = await getWithdrawals({
        page: 1,
        pageSize: totalRecords,
        Term: searchTerm,
      });

      if (result && result.items) {
        return result.items.map((withdrawal) => ({
          ID: withdrawal.id || "N/A",
          ChavePix: withdrawal.pixKey || "N/A",
          Valor: withdrawal.value
            ? new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(withdrawal.value)
            : "R$ 0,00",
          Status: withdrawal.enTransaction || "N/A",
          DataCriacao: withdrawal.createdAt
            ? new Date(withdrawal.createdAt).toLocaleString("pt-BR")
            : "N/A",
          DataAtualizacao: withdrawal.updateDate
            ? new Date(withdrawal.updateDate).toLocaleString("pt-BR")
            : "N/A",
          SistemaPagamento: withdrawal.enPaymentSystem || "N/A",
          Descricao: withdrawal.description || "N/A",
        }));
      } else {
        console.error("Nenhum dado encontrado para exportação.");
        return [];
      }
    } catch (error) {
      console.error("Erro ao buscar dados da API para exportação:", error);
      return [];
    }
  };

  return (
    <div>
      <h1 className="dash-title">Histórico de transferências</h1>
      <div className="table-section">
        <CustomDataTable
          data={data}
          columns={columns}
          exportFields={exportFields} // Campos exportáveis
          onExport={handleExportFromAPI} // Função de exportação
          title="Transferências"
          loading={loading}
          totalRecords={totalRecords}
          rows={pageSize}
          page={page}
          onPageChange={onPageChange}
          onSearch={onSearch}
          searchPlaceholder="Buscar por transferências ou sellers"
          showStatusColumn={true}
          statusField="Status"
          getStatusText={getStatusText}
          getStatusColor={getStatusColor}
          statusOptions={statusOptions}
        />
      </div>
    </div>
  );
};

export default AdminTransfers;
