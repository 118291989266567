import React, { useState } from "react";
import LoginComponent from "components/LoginComponent/LoginComponent";
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "services/authService";
import "./LoginAdmin.css";

function LoginAdmin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [loginError, setLoginError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    let hasError = false;

    if (!email) {
      setErrors((prevState) => ({
        ...prevState,
        email: "O email é obrigatório",
      }));
      hasError = true;
    }

    if (!password) {
      setErrors((prevState) => ({
        ...prevState,
        password: "A senha é obrigatória",
      }));
      hasError = true;
    }

    if (!hasError) {
      try {
        const { role } = await loginAdmin(email, password);

        if (role === "Admin") {
          navigate("/admin");
        } else {
          setLoginError("Acesso permitido apenas para administradores.");
        }
      } catch (error) {
        setLoginError("Credenciais inválidas. Tente novamente.");
        console.error("Erro durante o login:", error);
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-page">
        <div className="art-section"></div>
        <LoginComponent
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          handleLogin={handleLogin}
          loginError={loginError}
          errors={errors}
        />
      </div>
    </div>
  );
}

export default LoginAdmin;
