import React, { useState, useEffect, useCallback } from "react";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import { getTransactions } from "services/authService";
import "./AdminTransactions.css";

const AdminTransactions = () => {
  const [data, setData] = useState([]); // Dados processados da tabela
  const [page, setPage] = useState(0); // Página atual
  const [pageSize, setPageSize] = useState(10); // Itens por página
  const [totalRecords, setTotalRecords] = useState(0); // Total de registros
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [selectedRange, setSelectedRange] = useState(null); // Filtro por data
  const [searchTerm, setSearchTerm] = useState(""); // Termo de busca
  const [selectedStatus, setSelectedStatus] = useState(null);

  // Função para formatar valores como moeda brasileira
  const formatCurrency = (value) => {
    if (typeof value === "number") {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    }
    return "R$ 0,00"; // Valor padrão caso seja inválido
  };

  // Processa os dados antes de enviá-los para a tabela
  const processData = (transactions) => {
    return transactions.map((transaction) => ({
      id: transaction.id || "N/A",
      paymentmethod: transaction.enPaymentSystem || "N/A",
      cliente: transaction.lead?.name || "N/A",
      valor: formatCurrency(transaction.value || 0),
      produto:
        Array.isArray(transaction.items) && transaction.items.length > 0
          ? transaction.items[0].title || "N/A"
          : "N/A",
      status: transaction.enStatusTransaction || "N/A",
    }));
  };

  // Função para buscar transações
  const fetchTransactions = useCallback(
    async (page, pageSize, searchTerm = "") => {
      setLoading(true);

      try {
        const startDate = selectedRange?.from
          ? new Date(selectedRange.from).toISOString()
          : null;
        const endDate = selectedRange?.to
          ? new Date(selectedRange.to).toISOString()
          : null;

        const result = await getTransactions({
          page: page + 1,
          pageSize,
          startDate,
          endDate,
          term: searchTerm,
        });

        if (result && result.items && result.totalCount !== undefined) {
          setData(processData(result.items));
          setTotalRecords(result.totalCount);
        } else {
          console.error("Formato inesperado da resposta da API:", result);
          setData([]);
          setTotalRecords(0);
        }
      } catch (error) {
        console.error("Erro ao buscar transações:", error);
        setData([]);
        setTotalRecords(0);
      } finally {
        setLoading(false);
      }
    },
    [selectedRange]
  );

  // Atualiza as transações quando os parâmetros mudam
  useEffect(() => {
    fetchTransactions(page, pageSize, searchTerm);
  }, [page, pageSize, searchTerm, fetchTransactions]);

  const getStatusText = (status) => {
    switch (status) {
      case "Successful":
        return "Aprovado";
      case "Awaiting":
        return "Aguardando";
      case "Failure":
        return "Reprovado";
      case "Pending":
        return "Pendente";
      case "PreChargeback":
        return "Pré-Chargeback";
      case "Chargeback":
        return "Chargeback";
      case "Refunded":
        return "Estornado";
      default:
        return "Indefinido";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Successful":
        return "#1eff00";
      case "Awaiting":
        return "#fdc313";
      case "Failure":
        return "#f44336";
      case "Pending":
        return "#fdc313";
      case "PreChargeback":
        return "#f44336";
      case "Chargeback":
        return "#f44336";
      case "Refunded":
        return "#f44336";
      default:
        return "#6c757d";
    }
  };

  // Configurações das colunas da tabela
  const columns = [
    { field: "id", header: "ID" },
    { field: "cliente", header: "Cliente" },
    { field: "paymentmethod", header: "Forma de Pagamento" },
    { field: "valor", header: "Valor" },
    { field: "produto", header: "Produto" },
  ];

  const statusOptions = [
    { label: "Aprovado", value: "Successful" },
    { label: "Aguardando", value: "Awaiting" },
    { label: "Reprovado", value: "Failure" },
    { label: "Pendente", value: "Pending" },
    { label: "Pré-Chargeback", value: "PreChargeback" },
    { label: "Chargeback", value: "Chargeback" },
    { label: "Estornado", value: "Refunded" },
  ];

  // Callback para mudança de página
  const onPageChange = (event) => {
    setPage(event.page);
    setPageSize(event.rows);
  };

  // Callback para mudança de range de data
  const onDateRangeChange = (range) => {
    setSelectedRange(range);
  };

  // Callback para busca
  const onSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <div>
      <h1 className="dash-title">Histórico de Transações</h1>
      <div className="table-section">
        <CustomDataTable
          data={data}
          columns={columns}
          title="Todas as vendas"
          loading={loading}
          totalRecords={totalRecords}
          rows={pageSize}
          page={page}
          onPageChange={onPageChange}
          onDateRangeChange={onDateRangeChange}
          onSearch={onSearch}
          searchPlaceholder="Buscar"
          showStatusColumn={true}
          statusField="status"
          getStatusText={getStatusText}
          getStatusColor={getStatusColor}
          statusOptions={statusOptions}
        />
      </div>
    </div>
  );
};

export default AdminTransactions;
