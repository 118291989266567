import React, { useState } from "react";
import Modal from "react-modal";
import CustomInput from "components/CustomInput/CustomInput";
import "./WebhookModal.css";
import { sendWebhook } from "services/webhookService";
import { sendAdminWebhook } from "services/adminWebhookService";

Modal.setAppElement("#root");

const WebhookModal = ({ isOpen, onClose, userId, type }) => {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    awaiting: true,
    pending: true,
    successful: true,
    failure: true,
    refunded: true,
  });

  const checkboxLabels = {
    awaiting: "Aguardando Pagamento",
    pending: "Pendente",
    successful: "Pagamento Aprovado",
    failure: "Recusado",
    refunded: "Reembolsado",
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (!webhookUrl) {
      setError("URL do Webhook é obrigatória.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const webhookData = {
        userId,
        sendLink: webhookUrl,
        ...checkboxes,
      };

      // Decide qual função usar com base no `type`
      if (type === "admin") {
        await sendAdminWebhook(webhookData);
      } else {
        await sendWebhook(webhookData);
      }

      alert("Webhook enviado com sucesso!");
      setWebhookUrl("");
      onClose();
    } catch (error) {
      console.error(error);
      setError("Erro ao enviar webhook. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <div className="modal-header">
        <h2>{type === "admin" ? "Cadastrar Novo Webhook (Admin)" : "Cadastrar Novo Webhook"}</h2>
      </div>

      <div className="modal-body">
        <div className="form-group">
          <CustomInput
            label="URL do Webhook"
            type="url"
            name="webhook-url"
            value={webhookUrl}
            onChange={(e) => setWebhookUrl(e.target.value)}
            placeholder="https://exemplo.com/webhook"
            isRequired={true}
            errorMessage={error}
          />
        </div>

        <div className="form-group">
          <h3>Selecione os eventos:</h3>
          {Object.keys(checkboxes).map((key) => (
            <div key={key} className="checkbox-group">
              <label>
                <input
                  type="checkbox"
                  name={key}
                  checked={checkboxes[key]}
                  onChange={handleCheckboxChange}
                />
                {checkboxLabels[key]}
              </label>
            </div>
          ))}
        </div>

        {error && <p className="error-message">{error}</p>}
      </div>

      <div className="modal-footer">
        <button type="button" onClick={onClose} className="cancel-btn" disabled={loading}>
          Cancelar
        </button>
        <button
          type="button"
          onClick={handleSave}
          className="save-btn sacar-btn"
          disabled={loading}
        >
          {loading ? "Enviando..." : "Salvar"}
        </button>
      </div>
    </Modal>
  );
};

export default WebhookModal;
