import axios from "axios";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

export const getDistributionControl = async () => {
  try {
    const token = Cookies.get("authToken");
    console.log(Cookies.get("token"));
    const response = await axios.get(`${API_URL}/api/DistributionControl`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar dados de DistributionControl:", error);
    throw error;
  }
};

export const createDistributionControl = async (data) => {
  try {
    const token = Cookies.get("authToken");
    const response = await axios.post(`${API_URL}/api/DistributionControl`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao criar registro no DistributionControl:", error);
    throw error;
  }
};

export const updateDistributionControl = async (integrationName, data) => {
  try {
    const token = Cookies.get("authToken");
    const response = await axios.put(
      `${API_URL}/api/DistributionControl/${integrationName}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      `Erro ao atualizar registro no DistributionControl para ${integrationName}:`,
      error
    );
    throw error;
  }
};

export const deleteDistributionControl = async (id) => {
  try {
    const token = Cookies.get("authToken");
    const response = await axios.delete(`${API_URL}/api/DistributionControl/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Erro ao deletar registro no DistributionControl com ID ${id}:`, error);
    throw error;
  }
};