import React, {useState, useEffect, useCallback} from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import SalesStatistics from "components/DashboardComponents/SalesStatistics/SalesStatistics";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import PrimaryButton from "components/DashboardComponents/PrimaryButton/PrimaryButton";
import Modal from "react-modal";
import {ReactComponent as Chargeback} from "assets/icons/cancel.svg";
import {ReactComponent as Reembolsado} from "assets/icons/arrow.svg";
import {ReactComponent as TimeIcon} from "assets/icons/stopwatch.svg";
import {ReactComponent as CardIcon} from "assets/icons/card-icon.svg";
import {ReactComponent as PixIcon} from "assets/icons/pix-icon.svg";
import {getUserBalance, getUserSummary, getWithdrawals, updateUserBalance} from "services/authService";
import {useParams} from "react-router-dom";
import "./AdminMerchantBalance.css";

function AdminMerchantBalance() {
  const {userId} = useParams();
  const [balanceData, setBalanceData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInputValues, setModalInputValues] = useState({
    accBalance: "",
    accBalanceCredit: "",
    accBalanceCreditAwaiting: "",
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchWithdrawals = useCallback(
    async (page, pageSize, searchTerm = "") => {
      setLoading(true);
      try {
        const result = await getWithdrawals({
          UserId: userId,
          page: page + 1,
          pageSize,
          Term: searchTerm,
        });

        if (result && result.items && result.totalCount !== undefined) {
          setTableData(
            result.items.map((withdrawal) => ({
              value: withdrawal.value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              date: new Date(withdrawal.createdAt).toLocaleDateString("pt-BR"),
              status: withdrawal.enTransaction,
              method: withdrawal.enPaymentSystem,
            }))
          );
          setTotalRecords(result.totalCount);
        } else {
          console.error("Formato inesperado da resposta da API:", result);
        }
      } catch (error) {
        console.error("Erro ao buscar histórico de saques:", error);
      } finally {
        setLoading(false);
      }
    },
    [userId]
  );

  useEffect(() => {
    fetchWithdrawals(page, pageSize, searchTerm);
  }, [page, pageSize, searchTerm, fetchWithdrawals]);

  // Fetch dos dados (saldo e resumo)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        if (!userId) {
          throw new Error("User ID não encontrado na URL.");
        }

        const [summary, balance] = await Promise.all([
          getUserSummary(userId),
          getUserBalance(userId),
        ]);

        setSummaryData(summary);
        setBalanceData(balance);

        // Simula dados da tabela a partir do resumo
        const withdrawals = summary.withdrawals || [];
        setTableData(
          withdrawals.map((withdrawal) => ({
            value: withdrawal.amount,
            date: new Date(withdrawal.date).toLocaleDateString("pt-BR"),
            status: withdrawal.status,
          }))
        );
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  // Dados dos Cards
  const releasedBalance = {
    pix: balanceData?.accBalance || 0,
    creditCard: balanceData?.accBalanceCredit || 0,
    boleto: balanceData?.accBalanceBoleto || 0,
  };

  const awaitingBalance = {
    pix: balanceData?.accBalanceAwaiting || 0,
    creditCard: balanceData?.accBalanceCreditAwaiting || 0,
    boleto: balanceData?.accBalanceBoletoAwaiting || 0,
  };

  // Dados para o gráfico de estatísticas
  const salesData = [
    {
      icon: <Chargeback />,
      label: "Chargeback",
      value: summaryData?.chargebacks || 0,
    },
    {
      icon: <Reembolsado />,
      label: "Reembolsos",
      value: summaryData?.refunds || 0,
    },
    {
      icon: <Chargeback />,
      label: "Cancelado",
      value: summaryData?.cancellations || 0,
    },
  ];

  const customColors = ["#93BECE", "#fff", "#000"];

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  // Configurações das colunas da tabela
  const columns = [
    {field: "value", header: "Valor"},
    {field: "date", header: "Data"},
    {field: "method", header: "Método de Pagamento"},
  ];

  const statusOptions = [
    {label: "Aguardando", value: "Awaiting"},
    {label: "Pendente", value: "Pending"},
    {label: "Aprovado", value: "Successful"},
    {label: "Rejeitado", value: "Failure"},
    {label: "Chargeback", value: "Chargeback"},
    {label: "Estornado", value: "Refunded"},
    {label: "Pré-Chargeback", value: "PreChargeback"},
  ];

  const getStatusText = (status) => {
    switch (status) {
      case "Successful":
        return "Aprovado";
      case "Awaiting":
        return "Aguardando";
      case "Failure":
        return "Reprovado";
      case "Pending":
        return "Pendente";
      case "PreChargeback":
        return "Pré-Chargeback";
      case "Chargeback":
        return "Chargeback";
      case "Refunded":
        return "Estornado";
      default:
        return "Indefinido";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Successful":
        return "#1eff00";
      case "Awaiting":
        return "#fdc313";
      case "Failure":
        return "#f44336";
      case "Pending":
        return "#fdc313";
      case "PreChargeback":
        return "#f44336";
      case "Chargeback":
        return "#f44336";
      case "Refunded":
        return "#f44336";
      default:
        return "#6c757d";
    }
  };

  const onPageChange = (event) => {
    setPage(event.page);
    setPageSize(event.rows);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  if (loading) {
    return <div className="loading-spinner">Carregando...</div>;
  }

  if (error) {
    return <p>Erro ao carregar dados: {error}</p>;
  }

  const handleBalance = () => {
    // Popula os valores ao abrir o modal
    setModalInputValues({
      accBalance: balanceData?.accBalance ? Math.round(balanceData.accBalance * 100).toString() : "",
      accBalanceCredit: balanceData?.accBalanceCredit ? Math.round(balanceData.accBalanceCredit * 100).toString() : "",
      accBalanceCreditAwaiting: balanceData?.accBalanceCreditAwaiting ? Math.round(balanceData.accBalanceCreditAwaiting * 100).toString() : "",
      accBalanceFormatted: balanceData?.accBalance
        ? new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(balanceData.accBalance)
        : "",
      accBalanceCreditFormatted: balanceData?.accBalanceCredit
        ? new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(balanceData.accBalanceCredit)
        : "",
      accBalanceCreditAwaitingFormatted: balanceData?.accBalanceCreditAwaiting
        ? new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(balanceData.accBalanceCreditAwaiting)
        : "",
    });
    setIsModalOpen(true);
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;

    // Remove formatação e mantém apenas números
    const numericValue = value.replace(/\D/g, ""); // Remove tudo que não for dígito

    // Formata como BRL
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(parseFloat(numericValue) / 100 || 0); // Divide por 100 para tratar como centavos

    setModalInputValues((prevValues) => ({
      ...prevValues,
      [name]: numericValue, // Valor puro numérico (para backend e cálculos)
      [`${name}Formatted`]: formattedValue, // Valor formatado para exibição
    }));
  };

  const getInputValue = (name) =>
    modalInputValues[`${name}Formatted`] || ""; // Retorna o valor formatado

  const handleSaveBalance = async () => {
    try {
      const { accBalance, accBalanceCredit, accBalanceCreditAwaiting } = modalInputValues;
  
      await updateUserBalance(userId, {
        accBalance: parseFloat(accBalance) / 100, // Converte para reais
        accBalanceCredit: parseFloat(accBalanceCredit) / 100, // Converte para reais
        accBalanceCreditAwaiting: parseFloat(accBalanceCreditAwaiting) / 100, // Converte para reais
      });
  
      alert("Saldo atualizado com sucesso!");
      setIsModalOpen(false);
  
      // Recarrega a página
      window.location.reload();
    } catch (error) {
      console.error("Erro ao atualizar saldo:", error);
      alert(error.message || "Erro ao atualizar saldo");
    }
  };

  return (
    <div className="home-dashboard">
      <div className="dash-container">
        <h1 className="dash-title">Transferências</h1>
        <div className="dash-content-1">
          <PrimaryButton
            label="ALTERAR SALDO"
            icon="pi pi-dollar" // Ícone de envio do PrimeReact
            onClick={handleBalance}
            style={{
              height: "50%",
              marginLeft: "5px",
            }}
          />
        </div>
      </div>
      <div className="merchant-grids">
        <div className="merchant-content-1">
          <div className="merchant-grid-1">
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Saldo disponível"
                  salesData={releasedBalance.pix + releasedBalance.boleto}
                  salesColor="black"
                  totalSoldText=" "
                  salesMethodText="Métodos de pagamento selecionados"
                  icon={<PixIcon />}
                />
                <SalesOverviewCard
                  title="Saldo disponível"
                  salesData={releasedBalance.creditCard}
                  salesColor="black"
                  totalSoldText=" "
                  salesMethodText="Métodos de pagamento selecionados"
                  icon={<CardIcon />}
                />
                <SalesOverviewCard
                  title="Saldo Pendente"
                  salesData={
                    awaitingBalance.pix +
                    awaitingBalance.creditCard +
                    awaitingBalance.boleto
                  }
                  salesColor="black"
                  totalSoldText=" "
                  salesMethodText="Selecione para ver total líquido por método de pagamento"
                  icon={<TimeIcon />}
                />
              </div>
            </div>

            <div className="table-section">
              <div className="table-section">
                <CustomDataTable
                  data={tableData}
                  columns={columns}
                  title="Histórico de saques"
                  loading={loading}
                  totalRecords={totalRecords}
                  rows={pageSize}
                  page={page}
                  onPageChange={onPageChange}
                  onSearch={onSearch}
                  searchPlaceholder="Buscar por valor, data ou status"
                  showStatusColumn={true}
                  statusField="status"
                  getStatusText={getStatusText}
                  getStatusColor={getStatusColor}
                  statusOptions={statusOptions}
                />
              </div>
            </div>
          </div>
          <div className="merchant-grid-2">
            <SalesStatistics
              title="Chargebacks, Reembolsos e Cancelamentos"
              salesData={salesData}
              colors={customColors}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <h2>Alterar Saldo</h2>
        <p>OBS: Altere apenas o valor desejado e logo após clique em <strong>"SALVAR"</strong></p>
        <div className="modal-body-balance">
          <label>
            Saldo Disponível:
            <input
              type="text"
              name="accBalance"
              value={getInputValue("accBalance")}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Saldo em Crédito:
            <input
              type="text"
              name="accBalanceCredit"
              value={getInputValue("accBalanceCredit")}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Saldo Pendente:
            <input
              type="text"
              name="accBalanceCreditAwaiting"
              value={getInputValue("accBalanceCreditAwaiting")}
              onChange={handleInputChange}
            />
          </label>
        </div>
        <div className="modal-footer">
          <button onClick={handleCloseModal} className="cancel-btn">
            Cancelar
          </button>
          <button onClick={handleSaveBalance} className="sacar-btn">
            Salvar
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default AdminMerchantBalance;
