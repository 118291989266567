export function formatCPF(cpf) {
  cpf = cpf.replace(/\D/g, "");

  if (cpf.length <= 11) {
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  }

  return cpf;
}

export const formatCNPJ = (cnpj) => {
  return cnpj
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{4})(\d{2})$/, "$1/$2-$3");
};

export function formatPhone(phone) {
  phone = phone.replace(/\D/g, "");

  if (phone.length <= 10) {
    phone = phone.replace(/(\d{2})(\d)/, "($1) $2");
    phone = phone.replace(/(\d{4})(\d)/, "$1-$2");
  } else if (phone.length === 11) {
    phone = phone.replace(/(\d{2})(\d)/, "($1) $2");
    phone = phone.replace(/(\d{5})(\d)/, "$1-$2");
  }

  return phone;
}


export const formatCep = (value) => {
  const numericValue = value.replace(/\D/g, '');

  if (numericValue.length > 5) {
    return `${numericValue.slice(0, 5)}-${numericValue.slice(5, 8)}`;
  }
  return numericValue;
};