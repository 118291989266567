import axios from "axios";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

// Funções para login e cadastro de usuário e admin
export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/api/Auth/login`, {
      usernameOrEmail: email,
      password: password,
    });

    const { id, token, fullName, role, enStatusMerchant } = response.data;

    Cookies.set("authToken", token, {
      expires: 1,
      secure: true,
      sameSite: "strict",
    });

    localStorage.setItem("userId", id);
    localStorage.setItem("fullName", fullName);
    localStorage.setItem("role", role);
    localStorage.setItem("enStatusMerchant", enStatusMerchant);

 
    return { fullName, role, enStatusMerchant };
  } catch (error) {
    throw new Error(error.response?.data?.message || "Erro durante o login");
  }
};


export const loginAdmin = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/api/Auth/admin/login`, {
      usernameOrEmail: email,
      password: password,
    });

    const { id, token, fullName, role } = response.data;

    Cookies.set("authToken", token, {
      expires: 1,
      secure: true,
      sameSite: "strict",
    });

    localStorage.setItem("id", id);
    localStorage.setItem("fullName", fullName);
    localStorage.setItem("role", role);

    return { fullName, role };
  } catch (error) {
    throw new Error(error.response?.data?.message || "Erro durante o login");
  }
};

export const registerUser = async (userData) => {
  try {
    console.log(API_URL);
    const response = await axios.post(`${API_URL}/api/Auth/register`, userData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Erro durante o cadastro");
  }
};

// Função para buscar merchants com paginação
export const getMerchants = async ({ term, page, pageSize, enStatusMerchant, startDate, endDate }) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Usuário não autenticado. Faça login novamente.");
    }

    const requestBody = {
      ...(term && { term }),
      ...(enStatusMerchant && { enStatusMerchant }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      page,
      pageSize,
    };

    const response = await axios.post(
      `${API_URL}/api/User/search/Merchant`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar merchants:", error);
    throw new Error(error.response?.data?.message || "Erro ao buscar merchants");
  }
};


// Função para buscar os dados do usuário pelo userId
export const getUserKycData = async (userId) => {
  try {
    const token = Cookies.get('authToken'); 

    if (!token) {
      throw new Error('Token de autenticação não encontrado');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };

    const response = await axios.get(`${API_URL}/api/User/search/${userId}`, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error('Autenticação falhou, token inválido ou expirado');
    }
    throw new Error('Erro ao buscar dados do KYC');
  }
};

// Função para atualizar os dados do KYC
export const updateUserKycData = async (userId, kycData) => {
  try {
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('Token de autenticação não encontrado');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    console.log("Enviando dados para o servidor:", kycData);

    const response = await axios.put(`${API_URL}/api/User/${userId}`, kycData, config);
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar os dados do KYC:', error.response ? error.response.data : error.message);
    throw new Error('Erro ao atualizar os dados do KYC');
  }
};


export const fetchAddressByCep = async (cep) => {
  try {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
    const data = await response.json();
    if (data.erro) {
      alert('CEP não encontrado. Por favor, verifique o CEP.');
      return null;
    }
    return data;
  } catch (error) {
    alert('Erro ao buscar o endereço. Tente novamente.');
    return null;
  }
};

// Função para fazer upload dos documentos no KYC
export const uploadKycDocuments = async (userId, formData) => {
  try {
    const token = Cookies.get('authToken');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    const response = await axios.put(`${API_URL}/api/v1/KYC/${userId}`, formData, config);
    return response.data;
  } catch (err) {
    console.error("Erro ao enviar os documentos:", err);
    throw new Error('Erro ao enviar os documentos. Tente novamente.');
  }
};

export const addBankAccount = async (userId, bankData) => {
  try {
    const token = Cookies.get('authToken');
    
    if (!token) {
      throw new Error('Token de autenticação não encontrado');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    };

    const response = await axios.post(`${API_URL}/api/BankAccount/${userId}/bank-account`, bankData, config);
    
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error('Autenticação falhou, token inválido ou expirado');
    }
    throw new Error('Erro ao adicionar conta bancária');
  }
};

export const saveMerchantFees = async (userId, feeData) => {
  try {
    const token = Cookies.get('authToken');
    
    if (!token) {
      throw new Error('Token de autenticação não encontrado');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    };

    const response = await axios.post(`${API_URL}/api/fee-merchant`, {
      userId,
      feePix: feeData.feePix,
      feeCredit: feeData.feeCredit,
      feeTED: feeData.feeTED,
      feeOcult: feeData.feeOcult,
      reservePix: feeData.reservePix,
      reserveTED: feeData.reserveTED,
      reserveCredit: feeData.reserveCredit,
      creditReleaseDays: feeData.creditReleaseDays
    }, config);
    
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error('Autenticação falhou, token inválido ou expirado');
    }
    throw new Error('Erro ao salvar as taxas do merchant');
  }
};

// Função para buscar dados bancários do usuário
export const getUserBankAccount = async (userId) => {
  try {
    const token = Cookies.get('authToken');
    if (!token) {
      throw new Error('Token de autenticação não encontrado');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(`${API_URL}/api/BankAccount/${userId}/bank-account`, config);
    return response.data;
  } catch (error) {
    throw new Error('Erro ao buscar os dados bancários do cliente');
  }
};

export const fetchBanks = async () => {
  try {
    const response = await axios.get("https://brasilapi.com.br/api/banks/v1");
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar os bancos", error);
    return [];
  }
};

// Função para atualizar os dados bancários do usuário
export const updateUserBankAccount = async (userId, bankAccountData) => {
  try {
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('Token de autenticação não encontrado');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(`${API_URL}/api/BankAccount/${userId}/bank-account`, bankAccountData, config);
    return response.data;
  } catch (error) {
    throw new Error('Erro ao atualizar os dados bancários');
  }
};

export const getMerchantFees = async (userId) => {
  if (!userId) {
    throw new Error("userId não foi fornecido");
  }

  try {
    const token = Cookies.get('authToken');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };

    const response = await axios.get(`${API_URL}/api/fee-merchant/${userId}`, config);
    return response.data;
  } catch (error) {
    throw new Error("Erro ao carregar as taxas");
  }
};

// Função para atualizar as taxas do merchant pelo userId
export const updateMerchantFees = async (userId, feesData) => {
  try {
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('Token de autenticação não encontrado');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, 
      }
    };

    const response = await axios.put(`${API_URL}/api/fee-merchant/${userId}`, feesData, config);
    return response.data;
  } catch (error) {
    throw new Error("Erro ao atualizar as taxas");
  }
};

// Função para atualizar o saldo de um usuário pelo userId
export const updateUserBalance = async (userId, balanceData) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Token de autenticação não encontrado");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    console.log("Enviando para o backend:", { userId, ...balanceData });

    const response = await axios.put(`${API_URL}/api/Balance`, { userId, ...balanceData }, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.error("Erro de requisição:", error.response.data);
      throw new Error("Ocorreu um erro no envio dos dados. Verifique os campos.");
    }
    throw new Error(error.response?.data?.message || "Erro ao atualizar o saldo");
  }
};



// Função de logout
export const logout = async () => {
  try {
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('Token de autenticação não encontrado');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };

    await axios.get(`${API_URL}/api/Auth/logout`, config);

    Cookies.remove('authToken');
  } catch (error) {
    throw new Error("Erro ao fazer logout");
  }
};

// ENDPOINTS DE SALDO //
// Função para buscar o Summary de um usuário pelo userId
export const getUserSummary = async (userId) => {
  try {
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('Token de autenticação não encontrado');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(`${API_URL}/api/Summary/summary/${userId}`, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error('Autenticação falhou, token inválido ou expirado');
    }
    throw new Error(error.response?.data?.message || 'Erro ao buscar resumo do usuário');
  }
};

// Função para obter o saldo do usuário
export const getUserBalance = async (userId) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Token de autenticação não encontrado");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(`${API_URL}/api/Balance/balance/${userId}`, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Autenticação falhou, token inválido ou expirado");
    }
    throw new Error(error.response?.data?.message || "Erro ao buscar o saldo do usuário");
  }
};

// Função para obter o histórico de vendas
export const getSalesHistory = async (userId = null) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Token de autenticação não encontrado");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Monta a URL com ou sem o parâmetro userId
    const url = userId
      ? `${API_URL}/api/Reports/sales-history?userId=${userId}`
      : `${API_URL}/api/Reports/sales-history`;

    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Autenticação falhou, token inválido ou expirado");
    }
    throw new Error(error.response?.data?.message || "Erro ao obter histórico de vendas");
  }
};

// Função para buscar transações com filtros opcionais
export const getTransactions = async (filters) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Token de autenticação não encontrado");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${API_URL}/api/Transaction/transaction`, filters, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Autenticação falhou, token inválido ou expirado");
    }
    throw new Error(error.response?.data?.message || "Erro ao buscar transações");
  }
};

// Função para solicitar saque
export const requestCashOut = async (userId, amount, enPaymentSystem) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Token de autenticação não encontrado");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const body = {
      userId,
      amount,
      enPaymentSystem,
    };

    const response = await axios.post(`${API_URL}/api/v1/VolutiCashOut/request`, body, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Autenticação falhou, token inválido ou expirado");
    }
    throw new Error(error.response?.data?.message || "Erro ao solicitar saque");
  }
};

export const getWithdrawals = async (params) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Token de autenticação não encontrado");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${API_URL}/api/Withdraw/search/Merchant`, params, config);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Erro ao buscar histórico de saques");
  }
};

// Aprovar saque
export const approveWithdrawal = async (id) => {
  try {
    const token = Cookies.get("authToken");
    if (!token) {
      console.error("Erro: Token de autenticação não encontrado");
      throw new Error("Token de autenticação não encontrado");
    }

    const url = `${API_URL}/api/v1/VolutiCashOut/${id}/approve`;
    console.log("URL da requisição (Aprovar Saque):", url);
    console.log("Token enviado:", token);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(url, {}, config);
    console.log("Resposta do backend (Aprovar Saque):", response.data);
    return response.data;
  } catch (error) {
    console.error("Erro ao aprovar o saque:", error.response || error.message);
    if (error.response) {
      console.error("Detalhes do erro ao aprovar saque:", {
        status: error.response.status,
        data: error.response.data,
      });
    }
    throw new Error(error.response?.data?.message || "Erro ao aprovar o saque");
  }
};

// Aprovar saque
export const approveWithdrawalManual = async (id) => {
  try {
    const token = Cookies.get("authToken");
    if (!token) {
      console.error("Erro: Token de autenticação não encontrado");
      throw new Error("Token de autenticação não encontrado");
    }

    const url = `${API_URL}/api/v1/VolutiCashOut/${id}/manual/approve`;
    console.log("URL da requisição (Aprovar Saque):", url);
    console.log("Token enviado:", token);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(url, {}, config);
    console.log("Resposta do backend (Aprovar Saque):", response.data);
    return response.data;
  } catch (error) {
    console.error("Erro ao aprovar o saque:", error.response || error.message);
    if (error.response) {
      console.error("Detalhes do erro ao aprovar saque:", {
        status: error.response.status,
        data: error.response.data,
      });
    }
    throw new Error(error.response?.data?.message || "Erro ao aprovar o saque");
  }
};

// Reprovar saque
export const rejectWithdrawal = async (id) => {
  try {
    const token = Cookies.get("authToken");
    if (!token) {
      console.error("Erro: Token de autenticação não encontrado");
      throw new Error("Token de autenticação não encontrado");
    }

    const url = `${API_URL}/api/v1/VolutiCashOut/${id}/reject`;
    console.log("URL da requisição (Reprovar Saque):", url);
    console.log("Token enviado:", token);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(url, {}, config);
    console.log("Resposta do backend (Reprovar Saque):", response.data);
    return response.data;
  } catch (error) {
    console.error("Erro ao reprovar o saque:", error.response || error.message);
    if (error.response) {
      console.error("Detalhes do erro ao reprovar saque:", {
        status: error.response.status,
        data: error.response.data,
      });
    }
    throw new Error(error.response?.data?.message || "Erro ao reprovar o saque");
  }
};

