import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { getCreditInstallmentFees } from "services/feeService";
import "./TaxModal.css";

Modal.setAppElement("#root");

const TaxModal = ({ isOpen, onClose, title, userId }) => {
  const [fees, setFees] = useState([]);
  const [error, setError] = useState(null);

  const fetchFees = async () => {
    if (userId) {
      try {
        const data = await getCreditInstallmentFees(userId);
        setFees(data);
      } catch (err) {
        setError(err.message || "Erro ao carregar as taxas.");
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchFees();
    } else {
      setFees([]);
      setError(null);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <div className="modal-header">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <h2>{title}</h2>
      </div>

      <div className="modal-body">
        {error ? (
          <p className="error-message">{error}</p>
        ) : fees.length > 0 ? (
          <div className="fees-container">
            {fees.map((fee, index) => (
              <div className="fee-card" key={index}>
                <p className="fee-installments">{fee.installments}x</p>
                <p className="fee-percentage">{fee.feePercentage}%</p>
              </div>
            ))}
          </div>
        ) : (
          <p>Carregando taxas...</p>
        )}
      </div>
    </Modal>
  );
};

export default TaxModal;
 