import React from 'react';
import { Dialog } from 'primereact/dialog';
import './CustomDialog.css'; // Arquivo CSS para o diálogo

function CustomDialog({ visible, header, message, onHide }) {
  return (
    <Dialog
      header={header}
      visible={visible}
      style={{ width: '50vw' }}
      onHide={onHide}
      className="custom-dialog"
    >
      <p style={{color: "#000"}}>{message}</p>
    </Dialog>
  );
}

export default CustomDialog;
