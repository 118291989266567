import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import "./SalesStatistics.css";

const SalesStatistics = ({ salesData, title = "Estatísticas de vendas", colors }) => {
  const defaultColors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const chartColors = colors || defaultColors;

  return (
    <div className="sales-statistics">
      <h2 className="statistics-title">{title}</h2>
      <div className="chart-container">
        <PieChart width={400} height={400}>
          <Pie
            data={salesData}
            dataKey="value"
            nameKey="label"
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            label
          >
            {salesData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>
    </div>
  );
};

export default SalesStatistics;
