import React, { useEffect, useState } from "react";
import TaxCard from "components/DashboardComponents/TaxCard/TaxCard";
import RetainedCard from "components/DashboardComponents/RetainedCard/RetainedCard";
import { getMerchantFees, getUserSummary } from "services/authService";
import { ReactComponent as CreditCardIcon } from "assets/icons/card-icon.svg";
import TaxModal from "components/DashboardComponents/TaxModal/TaxModal";
import { ReactComponent as PixIcon } from "assets/icons/pix-icon.svg";
import { ReactComponent as BoletoIcon } from "assets/icons/boleto-icon.svg";
import "./MerchantTax.css";

const MerchantTax = ({ userId }) => {
  const [isTaxModalOpen, setIsTaxModalOpen] = useState(false);
  const [fees, setFees] = useState(null);
  const [retainedData, setRetainedData] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFeesAndSummary = async () => {
      try {
        if (!userId) {
          console.error("userId não está definido");
          return;
        }
        const [fetchedFees, userSummary] = await Promise.all([
          getMerchantFees(userId),
          getUserSummary(userId),
        ]);
        setFees(fetchedFees);
        setRetainedData(
          (
            userSummary.reservePix +
            userSummary.reserveCredit +
            userSummary.reserveTed
          ).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        );
        setLoading(false);
      } catch (error) {
        console.error("Erro ao carregar dados do Merchant:", error);
        setLoading(false);
      }
    };

    fetchFeesAndSummary();
  }, [userId]);

  if (loading) return <div>Carregando taxas...</div>;

  return (
    <div className="merchant-tax-container">
      <h1 className="dash-title">Minhas taxas</h1>
      <div className="tax-content">
        <div className="tax-item">
          <TaxCard
            title="Pix"
            description="Receba pagamentos instantâneos via Pix."
            mainRate={`${fees.feePix}%`}
            reserve={`Reserva financeira de ${fees.reservePix}%`}
            icon={<PixIcon />}
          />
        </div>

        <div className="tax-item">
          <TaxCard
            title="Boleto"
            description="Receba pagamentos via boleto bancário."
            mainRate={`${fees.feeTED}%`}
            reserve={`Reserva financeira de ${fees.reserveTED}%`}
            icon={<BoletoIcon />}
          />
        </div>

        <div className="tax-item">
          <TaxCard
            title="Cartão de Crédito"
            description={`Receba pagamentos com cartão de crédito em ${fees.creditReleaseDays} dias.`}
            mainRate=""
            reserve={`Reserva financeira de ${fees.reserveCredit}%`}
            icon={<CreditCardIcon />}
            showButton={true}
            buttonLabel="Ver Taxas"
            onButtonClick={() => setIsTaxModalOpen(true)}
          />
        </div>

        <div className="tax-item">
          <RetainedCard
            title="Reserva Financeira"
            description="Verifique sua reserva financeira"
            retainedAmount={retainedData}
            additionalInfo="A reserva financeira é liberada conforme seu contrato"
          />
        </div>
      </div>
      <TaxModal
        isOpen={isTaxModalOpen}
        onClose={() => setIsTaxModalOpen(false)}
        title="Detalhes das Taxas"
        userId={userId} // Passando o userId para o modal
      />
    </div>
  );
};

export default MerchantTax;
