import React, { createContext, useState, useEffect } from 'react';

// Cria o Context para o tema
export const ThemeContext = createContext();

// Provedor do tema
export const ThemeProvider = ({ children }) => {
  const [primaryColor, setPrimaryColor] = useState('#6f49fd');
  const [hoverColor, setHoverColor] = useState('#3d19c0');
  const [backgroundColor, setBackgroundColor] = useState('#D0DBEB');
  const [isDarkMode, setIsDarkMode] = useState(false); // Novo estado para Dark Mode

  // Atualiza as variáveis de tema personalizadas
  const updateTheme = (newColors) => {
    const { primary, hover, background } = newColors;
    document.documentElement.style.setProperty('--primary-color', primary);
    document.documentElement.style.setProperty('--primary-hover-color', hover);
    document.documentElement.style.setProperty('--background-color', background);

    setPrimaryColor(primary);
    setHoverColor(hover);
    setBackgroundColor(background);
  };

  // Alterna entre Dark e Light Mode
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  // Aplica as variáveis de CSS para o modo escuro ou claro
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.style.setProperty('--background-color', '#121212');
      document.documentElement.style.setProperty('--primary-color', '#bb86fc');
      document.documentElement.style.setProperty('--primary-hover-color', '#3700b3');
    } else {
      document.documentElement.style.setProperty('--background-color', backgroundColor);
      document.documentElement.style.setProperty('--primary-color', primaryColor);
      document.documentElement.style.setProperty('--primary-hover-color', hoverColor);
    }
  }, [isDarkMode, primaryColor, hoverColor, backgroundColor]);

  return (
    <ThemeContext.Provider
      value={{ primaryColor, hoverColor, backgroundColor, updateTheme, isDarkMode, toggleDarkMode }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
