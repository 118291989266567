import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Para redirecionamento
import DashboardLayout from "layouts/DashboardLayout/DashBoardLayout";
import AdminDashboard from "components/DashboardComponents/AdminDashboard/AdminDashboard";
import AdminTransactions from "components/DashboardComponents/AdminTransactions/AdminTransactions";
import AdminTransfers from "components/DashboardComponents/AdminTransfers/AdminTransfers";
import AdminRequests from "components/DashboardComponents/AdminRequests/AdminRequests";
import AdminWebhook from "components/DashboardComponents/AdminWebhook/AdminWebhook";
import AdminAcquirer from "components/DashboardComponents/AdminAcquirer/AdminAcquirer";
import MerchantController from "components/DashboardComponents/MerchantController/MerchantController";
import { logout } from "services/authService"; // Função de logout do authService
import "./AdminPanel.css";

function AdminPanel() {
  const [activeComponent, setActiveComponent] = useState("home");
  const [activeIcon, setActiveIcon] = useState(0);
  const [activeSecondIcon, setActiveSecondIcon] = useState(null);
  const navigate = useNavigate();
  
  // Função de logout com depuração
  const handleLogout = async () => {
    console.log("Logout iniciado"); // Depuração
    try {
      await logout();
      console.log("Logout concluído, redirecionando..."); // Depuração
      navigate("/login"); // Redireciona para a página de login após o logout
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  const firstSideBarIcons = [
    { iconClass: "pi-home", label: "Home", component: "home" },
    { iconClass: "pi-users", label: "Sellers", component: "controller" },
    { iconClass: "pi-dollar", label: "Vendas", component: "transactions" },
    { iconClass: "pi-arrow-right-arrow-left", label: "Saques", component: "requested" },
    { iconClass: "pi-history", label: "Histórico", component: "record" },
  ];

  const secondSideBarIcons = [
    { iconClass: "pi-link", label: "Webhook", component: "webhook" },
    { iconClass: "pi-sliders-h", label: "Adquirentes", component: "acquirer" },
    { iconClass: "pi-sign-out", label: "Sair", action: handleLogout },
  ];

  const handleFirstIconClick = (iconIndex) => {
    const icon = firstSideBarIcons[iconIndex];
    setActiveIcon(iconIndex);
    setActiveSecondIcon(null);
  
    if (icon.component) {
      setActiveComponent(icon.component);
    }
  };
  
  const handleSecondIconClick = (iconIndex) => {
    const icon = secondSideBarIcons[iconIndex];
    setActiveSecondIcon(iconIndex);
    setActiveIcon(null);
  
    if (icon.action) {
      icon.action();
    } else if (icon.component) {
      setActiveComponent(icon.component);
    }
  };

  return (
    <div className="admin-content">
      <DashboardLayout
          firstSideBarIcons={firstSideBarIcons}
          secondSideBarIcons={secondSideBarIcons}
          onFirstIconClick={handleFirstIconClick}
          onSecondIconClick={handleSecondIconClick}
          activeIcon={activeIcon}
          activeSecondIcon={activeSecondIcon}
      >
        {activeComponent === "home" && <AdminDashboard />}
        {activeComponent === "controller" && <MerchantController />}
        {activeComponent === "transactions" && <AdminTransactions />}
        {activeComponent === "requested" && <AdminRequests />}
        {activeComponent === "record" && <AdminTransfers />}
        {activeComponent === "webhook" && <AdminWebhook />}
        {activeComponent === "acquirer" && <AdminAcquirer />}
      </DashboardLayout>
    </div>
  );
}

export default AdminPanel;
