import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "layouts/DashboardLayout/DashBoardLayout";
import MerchantDashboard from "components/DashboardComponents/MerchantDashboard/MerchantDashboard";
import MerchantTransactions from "components/DashboardComponents/MerchantTransactions/MerchantTransactions";
import MerchantLeads from "components/DashboardComponents/MerchantLeads/MerchantLeads";
import MerchantBalance from "components/DashboardComponents/MerchantBalance/MerchantBalance";
import MerchantWebhook from "components/DashboardComponents/MerchantWebhook/MerchantWebhook";
import MerchantTax from "components/DashboardComponents/MerchantTax/MerchantTax";
import MerchantUser from "components/DashboardComponents/MerchantUser/MerchantUser";
import { logout } from "services/authService";
import './MerchantPanel.css';

function MerchantPanel() {
  const [activeComponent, setActiveComponent] = useState("home");
  const [activeIcon, setActiveIcon] = useState(0);
  const [activeSecondIcon, setActiveSecondIcon] = useState(null);
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");

  // Função de logout
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  // Ícones da Sidebar (parte superior)
  const firstSideBarIcons = [
    { iconClass: "pi-shop", label: "Home", component: "home" },
    { iconClass: "pi-shopping-cart", label: "Vendas", component: "sales" },
    { iconClass: "pi-address-book", label: "Clientes", component: "leads" },
    { iconClass: "pi-dollar", label: "Saques", component: "transfers" },
  ];

  // Ícones da antiga Topbar (agora na parte inferior da Sidebar)
  const secondSideBarIcons = [
    { iconClass: "pi-link", label: "Webhook", component: "webhook" },
    { iconClass: "pi-percentage", label: "Taxas", component: "tax" },
    { iconClass: "pi-cog", label: "Configurações", component: "user" },
    { iconClass: "pi-sign-out", label: "Sair", action: handleLogout },
  ];

  const handleFirstIconClick = (iconIndex) => {
    const icon = firstSideBarIcons[iconIndex];
    setActiveIcon(iconIndex);
    setActiveSecondIcon(null);
  
    if (icon.component) {
      setActiveComponent(icon.component);
    }
  };
  
  const handleSecondIconClick = (iconIndex) => {
    const icon = secondSideBarIcons[iconIndex];
    setActiveSecondIcon(iconIndex);
    setActiveIcon(null);
  
    if (icon.action) {
      icon.action();
    } else if (icon.component) {
      setActiveComponent(icon.component);
    }
  };

  return (
    <div>
      <div className="merchant-content">
        <DashboardLayout
          firstSideBarIcons={firstSideBarIcons}
          secondSideBarIcons={secondSideBarIcons}
          onFirstIconClick={handleFirstIconClick}
          onSecondIconClick={handleSecondIconClick}
          activeIcon={activeIcon}
          activeSecondIcon={activeSecondIcon}
        >
          {/* Renderiza o componente baseado no estado ativo */}
          {activeComponent === "home" && <MerchantDashboard userId={userId} />}
          {activeComponent === "sales" && <MerchantTransactions userId={userId} />}
          {activeComponent === "leads" && <MerchantLeads userId={userId} />}
          {activeComponent === "transfers" && <MerchantBalance userId={userId} />}
          {activeComponent === "webhook" && <MerchantWebhook userId={userId} />}
          {activeComponent === "tax" && <MerchantTax userId={userId} />}
          {activeComponent === "user" && <MerchantUser userId={userId} />}
        </DashboardLayout>
      </div>
    </div>
  );
}

export default MerchantPanel;
