import axios from "axios";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

export const sendAdminWebhook = async (webhookData) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Token de autenticação não encontrado.");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await axios.post(`${API_URL}/api/AdminSendWebhook`, webhookData, { headers });

    return response.data;
  } catch (error) {
    console.error("Erro ao enviar webhook:", error.response?.data || error.message);
    throw error;
  }
};

export const getAdminWebhook = async () => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Token de autenticação não encontrado.");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.get(`${API_URL}/api/AdminSendWebhook`, { headers });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar webhook:", error.response?.data || error.message);
    throw error;
  }
};

export const deleteAdminWebhook = async (id) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Token de autenticação não encontrado.");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.delete(`${API_URL}/api/AdminSendWebhook/${id}`, { headers });

    return response.data;
  } catch (error) {
    console.error("Erro ao deletar webhook:", error.response?.data || error.message);
    throw error;
  }
};
