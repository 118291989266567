import axios from "axios";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

export const getCreditInstallmentFees = async (userId) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Usuário não autenticado. Faça login novamente.");
    }

    const response = await axios.get(
      `${API_URL}/api/CreditInstallmentFees/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          userId,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar taxas de parcelamento:", error);
    throw new Error(
      error.response?.data?.message || "Erro ao buscar taxas de parcelamento"
    );
  }
};

export const createCreditInstallmentFee = async (data) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Usuário não autenticado. Faça login novamente.");
    }

    const response = await axios.post(
      `${API_URL}/api/CreditInstallmentFees/user-specific`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao criar taxa de parcelamento:", error);
    throw new Error(
      error.response?.data?.message || "Erro ao criar taxa de parcelamento"
    );
  }
};

export const updateUserFees = async (data) => {
  try {
    const token = Cookies.get("authToken");

    if (!token) {
      throw new Error("Usuário não autenticado. Faça login novamente.");
    }

    const response = await axios.post(
      `${API_URL}/api/CreditInstallmentFees/update-user-fees`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar taxas de usuário:", error);
    throw error;
  }
};
