import React from 'react';
import { Navigate } from 'react-router-dom';

import Cookies from "js-cookie"; // Não se esqueça de importar o Cookies

function ProtectedRoute({ children, requiredRole }) {
  const token = Cookies.get('authToken'); // Pegando o token do cookie
  const role = localStorage.getItem('role');
  
  console.log('Token:', token);
  console.log('Role:', role);

  if (!token) {
    console.log('Redirecionando para login, falta de token...');
    return <Navigate to="/login" replace />;
  }

  if (role && role.toLowerCase() !== requiredRole.toLowerCase()) {
    console.log('Redirecionando para login, role não corresponde...');
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default ProtectedRoute;
