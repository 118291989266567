import React, { useState, useEffect } from "react";
import { SelectButton } from "primereact/selectbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import {
  getUserKycData,
  updateUserKycData,
  getUserBankAccount,
  updateUserBankAccount,
  getMerchantFees,
} from "services/authService";
import CustomInput from "components/CustomInput/CustomInput";
import PrimaryButton from "components/DashboardComponents/PrimaryButton/PrimaryButton";
import "./MerchantUser.css";

const MerchantUser = ({ userId }) => {
  // Recebendo o userId como prop
  const baseUrl = "https://unique-pag-kyc.s3.us-east-2.amazonaws.com/";

  const options = [
    { label: "Informações", value: "information", icon: "pi pi-info-circle" },
    { label: "Documentos", value: "documents", icon: "pi pi-file" },
    { label: "Contas bancárias", value: "accounts", icon: "pi pi-wallet" },
    { label: "Credenciais de API", value: "api", icon: "pi pi-globe" },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0].value);
  const [merchantData, setMerchantData] = useState({
    fullName: "",
    cpfCnpj: "",
    phoneNumber: "",
    email: "",
    street: "",
    streetNumber: "",
    neighborhood: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    enStatusMerchant: "Awaiting",
    selfieUrl: "",
    selfieDocUrl: "",
    docFrontUrl: "",
    docBackUrl: "",
    contractUrl: "",
  });

  const [bankAccountData, setBankAccountData] = useState({
    bankName: "",
    bankCode: "",
    pixKey: "",
  });

  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Puxa os dados de KYC do usuário
        const data = await getUserKycData(userId);
        console.log("Dados de KYC recebidos:", data);
        setMerchantData((prevData) => ({
          ...prevData,
          ...data,
        }));
      } catch (error) {
        setError(error.message);
        console.error("Erro ao buscar dados de KYC:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchBankAccountData = async () => {
      try {
        const bankData = await getUserBankAccount(userId);
        console.log("Dados bancários recebidos:", bankData);
        setBankAccountData(bankData);
      } catch (error) {
        console.error("Erro ao buscar dados bancários:", error);
      }
    };

    const fetchTaxData = async () => {
      try {
        const taxData = await getMerchantFees(userId);
        console.log("Dados de taxas recebidos:", taxData);
        setMerchantData((prevData) => ({
          ...prevData,
          feePix: taxData.feePix,
          reservePix: taxData.reservePix,
          feeCredit: taxData.feeCredit,
          reserveCredit: taxData.reserveCredit,
          feeTED: taxData.feeTED,
          reserveTED: taxData.reserveTED,
          feeOcult: taxData.feeOcult,
          creditReleaseDays: taxData.creditReleaseDays,
        }));
      } catch (error) {
        console.error("Erro ao buscar taxas:", error);
      }
    };

    const fetchAllData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          fetchMerchantData(),
          fetchBankAccountData(),
          fetchTaxData(),
        ]);
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
        setError("Erro ao carregar os dados.");
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchAllData();
    }
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMerchantData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBankInputChange = (e) => {
    const { name, value } = e.target;
    setBankAccountData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      await updateUserKycData(userId, merchantData);
      alert("Dados atualizados com sucesso!");
    } catch (error) {
      alert("Erro ao salvar as alterações.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveBankAccountChanges = async () => {
    setIsSaving(true);
    try {
      await updateUserBankAccount(userId, bankAccountData);
      alert("Dados bancários atualizados com sucesso!");
    } catch (error) {
      alert("Erro ao atualizar os dados bancários.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.value);
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro: {error}</div>;
  }

  const getImageUrl = (path) => {
    return `${baseUrl}${path}`;
  };

  return (
    <div className="merchant-user-container">
      <div className="config-title">
        <h2>Configurações</h2>
      </div>
      <div className="config-section">
        <div className="tab-switch">
          <SelectButton
            value={selectedOption}
            onChange={handleOptionChange}
            options={options}
            className="tab-switch-button disabled-select"
            optionLabel="label"
            itemTemplate={(option) => (
              <div className="custom-option">
                <i className={option.icon} style={{ marginRight: "8px" }}></i>
                <span>{option.label}</span>
              </div>
            )}
            buttonClassName={(option) =>
              selectedOption === option.value ? "" : "disabled-select"
            }
          />
        </div>

        <div className="select-container">
          {selectedOption === "information" && (
            <div className="information-details">
              <h2>Informações da Conta</h2>
              <div className="information-container">
                <div className="information-content information-content-1">
                  <CustomInput
                    label="Nome/Razão Social"
                    name="fullName"
                    value={merchantData.fullName}
                    onChange={handleInputChange}
                    isRequired={true}
                  />

                  <CustomInput
                    label="Email"
                    name="email"
                    value={merchantData.email}
                    onChange={handleInputChange}
                    isRequired={true}
                  />

                  <div className="personal-information">
                    <CustomInput
                      label="CPF/CNPJ"
                      name="cpfCnpj"
                      value={merchantData.cpfCnpj}
                      onChange={handleInputChange}
                      isRequired={true}
                      style={{ marginRight: "10px" }}
                      disabled
                    />
                    <CustomInput
                      label="Telefone"
                      name="phoneNumber"
                      value={merchantData.phoneNumber}
                      onChange={handleInputChange}
                      isRequired={true}
                    />
                  </div>
                </div>
                <div className="information-content information-content-2">
                  <CustomInput
                    label="Rua"
                    name="street"
                    value={merchantData.street}
                    onChange={handleInputChange}
                    isRequired={true}
                  />
                  <div className="personal-information">
                    <CustomInput
                      label="Número"
                      name="streetNumber"
                      value={merchantData.streetNumber}
                      onChange={handleInputChange}
                      isRequired={true}
                      style={{ marginRight: "10px" }}
                    />
                    <CustomInput
                      label="Bairro"
                      name="neighborhood"
                      value={merchantData.neighborhood}
                      onChange={handleInputChange}
                      isRequired={true}
                    />
                  </div>

                  <div className="personal-information">
                    <CustomInput
                      label="Cidade"
                      name="city"
                      value={merchantData.city}
                      onChange={handleInputChange}
                      isRequired={true}
                      style={{ marginRight: "10px" }}
                    />

                    <CustomInput
                      label="Estado"
                      name="state"
                      value={merchantData.state}
                      onChange={handleInputChange}
                      isRequired={true}
                    />
                  </div>

                  <div className="personal-information">
                    <CustomInput
                      label="CEP"
                      name="zipCode"
                      value={merchantData.zipCode}
                      onChange={handleInputChange}
                      isRequired={true}
                      style={{ marginRight: "10px" }}
                    />

                    <CustomInput
                      label="País"
                      name="country"
                      value={merchantData.country}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <PrimaryButton
                  label={isSaving ? "Salvando..." : "Salvar Alterações"}
                  icon="pi pi-save"
                  onClick={handleSaveChanges}
                  className="save-button"
                  disabled={isSaving}
                />
              </div>
            </div>
          )}

          {/* Seção de Documentos */}
          {selectedOption === "documents" && (
            <div className="documents-details">
              <h2>Documentos</h2>
              <Accordion multiple activeIndex={[0]}>
                <AccordionTab header="Selfie">
                  {merchantData.selfieUrl ? (
                    <img
                      src={getImageUrl(merchantData.selfieUrl)}
                      alt="Selfie"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  ) : (
                    <p>Sem selfie enviada</p>
                  )}
                </AccordionTab>

                <AccordionTab header="Selfie com Documento">
                  {merchantData.selfieDocUrl ? (
                    <img
                      src={getImageUrl(merchantData.selfieDocUrl)}
                      alt="Selfie com Documento"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  ) : (
                    <p>Sem selfie com documento enviada</p>
                  )}
                </AccordionTab>

                <AccordionTab header="Frente do Documento">
                  {merchantData.docFrontUrl ? (
                    <img
                      src={getImageUrl(merchantData.docFrontUrl)}
                      alt="Frente do Documento"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  ) : (
                    <p>Sem documento frente enviado</p>
                  )}
                </AccordionTab>

                <AccordionTab header="Verso do Documento">
                  {merchantData.docBackUrl ? (
                    <img
                      src={getImageUrl(merchantData.docBackUrl)}
                      alt="Verso do Documento"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  ) : (
                    <p>Sem documento verso enviado</p>
                  )}
                </AccordionTab>

                <AccordionTab header="Contrato Social (Pessoa Jurídica)">
                  {merchantData.contractUrl ? (
                    <a
                      href={getImageUrl(merchantData.contractUrl)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visualizar Contrato (PDF)
                    </a>
                  ) : (
                    <p>Sem contrato social enviado</p>
                  )}
                </AccordionTab>
              </Accordion>
            </div>
          )}

          {/* Seção de Contas Bancárias */}
          {selectedOption === "accounts" && (
            <div className="accounts-details">
              <h2>Dados bancários</h2>

              <div className="information-container">
                <div className="information-content information-content-1">
                  <div className="personal-information">
                    <CustomInput
                      label="Nome do Banco"
                      name="bankName"
                      value={bankAccountData.bankName}
                      onChange={handleBankInputChange}
                      isRequired={true}
                      style={{
                        marginRight: "10px",
                      }}
                    />

                    <CustomInput
                      label="Código do Banco"
                      name="bankCode"
                      value={bankAccountData.bankCode}
                      onChange={handleBankInputChange}
                      isRequired={true}
                    />
                  </div>

                  <CustomInput
                    label="Chave Pix"
                    name="pixKey"
                    value={bankAccountData.pixKey}
                    onChange={handleBankInputChange}
                    isRequired={true}
                  />
                </div>
                <PrimaryButton
                  label={isSaving ? "Salvando..." : "Salvar Dados Bancários"}
                  icon="pi pi-save"
                  onClick={handleSaveBankAccountChanges} // Botão para salvar dados bancários
                  className="save-button"
                  disabled={isSaving}
                />
              </div>
            </div>
          )}

          {/* Seção de Credenciais de API */}
          {selectedOption === "api" && (
            <div className="accounts-details">
              <h2>Credenciais de API</h2>

              {/* Seção de Credenciais de API */}
              <div className="api-content">
                <p>Client Id e Secret Key para integrações via API</p>
                <p>Guarde esses itens com cuidado!</p>
                <div className="api-details">
                  {/* Campo de Client ID */}
                  <CustomInput
                    label="Client ID"
                    name="clientId"
                    value={merchantData.clientId}
                    isRequired={true}
                    disabled // Campo bloqueado
                    rightIcon="pi pi-copy" // Ícone de copiar
                    onRightIconClick={() =>
                      navigator.clipboard.writeText(merchantData.clientId)
                    } // Função de cópia
                    placeholder="Client ID"
                  />

                  {/* Campo de Secret Key */}
                  <CustomInput
                    label="Secret Key"
                    name="secretKey"
                    type="password" // Exibe o campo como senha
                    value={merchantData.secretKey}
                    isRequired={true}
                    disabled // Campo bloqueado
                    rightIcon="pi pi-copy" // Ícone de copiar
                    onRightIconClick={() =>
                      navigator.clipboard.writeText(merchantData.secretKey)
                    } // Função de cópia
                    placeholder="Secret Key"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MerchantUser;
