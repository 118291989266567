import React from "react";
import { Button } from "primereact/button";
import "./PrimaryButton.css";

const PrimaryButton = ({
  label = "Entrar",       // Texto padrão
  icon = "pi pi-sign-in", // Ícone padrão (usando PrimeReact, mas pode ser removido)
  onClick,               // Função de clique passada por props
  className = "",        // Classes CSS adicionais
  style = {},            // Estilos inline adicionais
  backgroundColor = "var(--third-text-color)", // Cor de fundo opcional
  textColor = "var(--icons-sidebar)", // Cor do texto // Cor da borda opcional
  iconPos = "right",     // Posição do ícone (default: direita)
}) => {
  return (
    <Button
      label={label}
      icon={icon} // Ícone, se nenhum for passado, não será renderizado
      iconPos={iconPos} // Ícone à esquerda, direita ou removido
      className={`content-button ${className}`} // Classe padrão + customizável
      onClick={onClick}
      style={{
        padding: '0.7rem',
        backgroundColor: backgroundColor, // Cor de fundo
        color: textColor,                 // Cor do texto
        ...style,                         // Estilos adicionais passados via props
      }}
    />
  );
};

export default PrimaryButton;
