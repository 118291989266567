import React, { useState, useEffect, useCallback } from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import SalesStatistics from "components/DashboardComponents/SalesStatistics/SalesStatistics";
import DateRangePicker from "components/DashboardComponents/DayPicker/DayPicker";
import LineChart from "components/LineChart/LineChart";
import { ReactComponent as ApprovedIcon } from "assets/icons/clipboard-check.svg";
import { ReactComponent as AnalysisIcon } from "assets/icons/clipboard-minus.svg";
import { ReactComponent as ReprovedIcon } from "assets/icons/clipboard-x.svg";
import { ReactComponent as PixIcon } from "assets/icons/pix-icon.svg";
import { ReactComponent as CardIcon } from "assets/icons/card-icon.svg";
import { ReactComponent as BoletoIcon } from "assets/icons/boleto-icon.svg";
import { getTransactions } from "services/authService";
import "./AdminDashboard.css";

function AdminDashboard({ onDateRangeChange }) {
  const [selectedRange, setSelectedRange] = useState({
    from: new Date(new Date().setDate(new Date().getDate() - 7)),
    to: new Date(),
  });
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleDateRangeChange = (range) => {
    setSelectedRange(range);
    if (onDateRangeChange) {
      onDateRangeChange(range);
    }
  };

  const fetchTransactions = useCallback(async () => {
    try {
      setLoading(true);

      const startDate = selectedRange?.from
        ? new Date(selectedRange.from).toISOString()
        : null;
      const endDate = selectedRange?.to
        ? new Date(selectedRange.to).toISOString()
        : null;

      const data = await getTransactions({
        startDate,
        endDate,
        page: 1,
        pageSize: 50,
      });

      setTransactionData(data.items || []);
    } catch (err) {
      setError(err.message);
      setTransactionData([]);
    } finally {
      setLoading(false);
    }
  }, [selectedRange]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const processData = () => {
    const approved = transactionData.filter(
      (t) => t.enStatusTransaction === "Successful"
    );
    const Awaiting = transactionData.filter(
      (t) => t.enStatusTransaction === "Awaiting"
    );
    const Failure = transactionData.filter(
      (t) => t.enStatusTransaction === "Failure"
    );

    return {
      approved,
      Awaiting,
      Failure,
    };
  };

  const { approved, Awaiting, Failure } = processData();

  const approvedSalesData = {
    pix: approved
      .filter((t) => t.enPaymentSystem === "Pix")
      .reduce((sum, t) => sum + t.value, 0),
    creditCard: approved
      .filter((t) => t.enPaymentSystem === "Credit")
      .reduce((sum, t) => sum + t.value, 0),
    boleto: approved
      .filter((t) => t.enPaymentSystem === "Ted")
      .reduce((sum, t) => sum + t.value, 0),
  };

  const AwaitingSalesData = {
    pix: Awaiting.filter((t) => t.enPaymentSystem === "Pix").reduce(
      (sum, t) => sum + t.value,
      0
    ),
    creditCard: Awaiting.filter((t) => t.enPaymentSystem === "Credit").reduce(
      (sum, t) => sum + t.value,
      0
    ),
    boleto: Awaiting.filter((t) => t.enPaymentSystem === "Ted").reduce(
      (sum, t) => sum + t.value,
      0
    ),
  };

  const FailureSalesData = {
    pix: Failure.filter((t) => t.enPaymentSystem === "Pix").reduce(
      (sum, t) => sum + t.value,
      0
    ),
    creditCard: Failure.filter((t) => t.enPaymentSystem === "Credit").reduce(
      (sum, t) => sum + t.value,
      0
    ),
    boleto: Failure.filter((t) => t.enPaymentSystem === "Ted").reduce(
      (sum, t) => sum + t.value,
      0
    ),
  };

  const salesStatisticsData = (transactions) => [
    {
      icon: <PixIcon />,
      label: "PIX",
      value: transactions
        .filter((t) => t.enPaymentSystem === "Pix")
        .reduce((acc, t) => acc + t.value, 0),
    },
    {
      icon: <CardIcon />,
      label: "Cartão de crédito",
      value: transactions
        .filter((t) => t.enPaymentSystem === "Credit")
        .reduce((acc, t) => acc + t.value, 0),
    },
    {
      icon: <BoletoIcon />,
      label: "Boleto",
      value: transactions
        .filter((t) => t.enPaymentSystem === "Ted")
        .reduce((acc, t) => acc + t.value, 0),
    },
  ];

  const tableData = transactionData.map((t) => ({
    transaction: t.id,
    value: `R$ ${t.value.toFixed(2)}`,
    date: new Date(t.createdAt).toLocaleDateString("pt-BR"),
    status: t.enStatusTransaction,
  }));

  const columns = [
    { field: "transaction", header: "Transação" },
    { field: "value", header: "Valor" },
    { field: "date", header: "Data" }
  ];

  const timeLabels = [
    ...new Set(
      transactionData.map((t) =>
        new Date(t.createdAt).toLocaleDateString("pt-BR")
      )
    ),
  ]
    .map((date) => {
      const [day, month, year] = date.split("/").map(Number);
      return new Date(year, month - 1, day); // Converte "DD/MM/YYYY" para objeto Date
    })
    .sort((a, b) => a - b) // Ordena as datas em ordem crescente
    .map(
      (date) => date.toLocaleDateString("pt-BR") // Volta para o formato "DD/MM/YYYY"
    );

  const hubData = [
    {
      label: "Vendas Aprovadas",
      data: timeLabels.map((date) =>
        approved
          .filter(
            (t) => new Date(t.createdAt).toLocaleDateString("pt-BR") === date
          )
          .reduce((sum, t) => sum + t.value, 0)
      ),
      color: "#93BECE",
    },
    {
      label: "Vendas em Análise",
      data: timeLabels.map((date) =>
        Awaiting.filter(
          (t) => new Date(t.createdAt).toLocaleDateString("pt-BR") === date
        ).reduce((sum, t) => sum + t.value, 0)
      ),
      color: "#fff",
    },
    {
      label: "Vendas Reprovadas",
      data: timeLabels.map((date) =>
        Failure.filter(
          (t) => new Date(t.createdAt).toLocaleDateString("pt-BR") === date
        ).reduce((sum, t) => sum + t.value, 0)
      ),
      color: "#000",
    },
  ];

  const getStatusText = (status) => {
    switch (status) {
      case "Successful":
        return "Aprovada";
      case "Awaiting":
        return "Em Análise";
      case "Failure":
        return "Reprovada";
      default:
        return "Indefinido";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Successful":
        return "#1eff00"; // Verde
      case "Awaiting":
        return "#fdc313"; // Amarelo
      case "Failure":
        return "#f44336"; // Vermelho
      default:
        return "#6c757d"; // Cinza (Indefinido)
    }
  };

  const customColors = ["#93BECE", "#fff", "#000"];

  if (loading) {
    return <div className="loading-spinner">Carregando...</div>;
  }

  if (error) {
    return <p>Erro ao carregar dados: {error}</p>;
  }

  return (
    <div className="home-dashboard">
      <div className="dash-admin-container">
        <h1 className="dash-title">Dashboard</h1>
        <div className="dash-content-1">
          <DateRangePicker
            selectedRange={selectedRange}
            setSelectedRange={handleDateRangeChange}
          />
        </div>
      </div>

      <div className="admin-grids">
        <div className="admin-content-1">
          <div className="admin-grid-1">
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Vendas Aprovadas"
                  salesData={
                    approvedSalesData.pix +
                    approvedSalesData.boleto +
                    approvedSalesData.creditCard
                  }
                  salesColor="black"
                  totalSoldText=" "
                  icon={<ApprovedIcon />}
                />
                <SalesOverviewCard
                  title="Vendas em Análise"
                  salesData={
                    AwaitingSalesData.pix +
                    AwaitingSalesData.boleto +
                    AwaitingSalesData.creditCard
                  }
                  salesColor="black"
                  totalSoldText=" "
                  icon={<AnalysisIcon />}
                />
                <SalesOverviewCard
                  title="Vendas Reprovadas"
                  salesData={
                    FailureSalesData.pix +
                    FailureSalesData.boleto +
                    FailureSalesData.creditCard
                  }
                  salesColor="black"
                  totalSoldText=" "
                  icon={<ReprovedIcon />}
                />
              </div>
              <div className="charts-section">
                <div className="line-charts1">
                  <LineChart
                    title="Histórico de Vendas"
                    timeLabels={timeLabels}
                    hubData={hubData}
                  />
                </div>
              </div>
              <div className="table-section">
                <CustomDataTable
                  data={tableData}
                  columns={columns}
                  title="Transações Recentes"
                  loading={loading}
                  totalRecords={transactionData.length}
                  rows={10}
                  page={0}
                  onPageChange={({ page, rows }) => {
                    console.log(
                      `Página alterada: ${page}, Linhas por página: ${rows}`
                    );
                  }}
                  onSearch={(term) => {
                    console.log(`Buscando por: ${term}`);
                  }}
                  searchPlaceholder="Busque por transação, valor ou status"
                  showStatusColumn={true}
                  statusField="status"
                  getStatusText={getStatusText}
                  getStatusColor={getStatusColor}
                />
              </div>
            </div>
          </div>
          <div className="admin-grid-2">
            <SalesStatistics
              title="Vendas Aprovadas"
              salesData={salesStatisticsData(approved)}
              colors={customColors}
            />
            <SalesStatistics
              title="Vendas em análise"
              salesData={salesStatisticsData(Awaiting)}
              colors={customColors}
            />
            <SalesStatistics
              title="Vendas reprovadas"
              salesData={salesStatisticsData(Failure)}
              colors={customColors}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
