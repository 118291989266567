import React, { useEffect, useState } from "react";
import { getWebhookByUserId, deleteWebhook } from "services/webhookService";
import PrimaryButton from "components/DashboardComponents/PrimaryButton/PrimaryButton";
import WebhookModal from "components/DashboardComponents/WebhookModal/WebhookModal";
import SimpleTable from "components/DashboardComponents/SimpleTable/SimpleTable";
import "./MerchantWebhook.css";

const MerchantWebhook = ({ userId }) => { 
  const [webhooks, setWebhooks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchWebhooks = async () => {
      try {
        const result = await getWebhookByUserId(userId);
        setWebhooks(result);
      } catch (error) {
        console.error("Failed to fetch webhooks:", error);
      }
    };

    fetchWebhooks();
  }, [userId]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSaveWebhook = async (newWebhook) => {
    setIsModalOpen(false);
    setWebhooks((prevWebhooks) => [...prevWebhooks, newWebhook]);
  };

  const handleDeleteWebhook = async (webhook) => {
    try {
      await deleteWebhook(webhook.id);
      alert(`Webhook com ID ${webhook.id} deletado com sucesso!`);
      setWebhooks((prevWebhooks) => prevWebhooks.filter((item) => item.id !== webhook.id));
    } catch (error) {
      console.error("Erro ao deletar webhook:", error);
      alert("Erro ao deletar webhook. Tente novamente mais tarde.");
    }
  };

  const columns = [
    { field: "id", header: "ID" },
    { field: "sendLink", header: "Webhook URL" },
  ];

  return (
    <div className="merchant-tax-container">
      <h1 className="dash-title">Webhook</h1>
      {webhooks.length === 0 ? (
        <div className="webhook-container">
          <h2>Não há webhooks cadastrados.</h2>
          <PrimaryButton
            label="Cadastrar Webhook"
            icon="pi pi-plus"
            onClick={handleModalOpen}
            className="register-webhook-button"
          />
        </div>
      ) : (
        <>
          <PrimaryButton
            label="Adicionar Webhook"
            icon="pi pi-plus"
            onClick={handleModalOpen}
            className="add-webhook-button"
          />
          <SimpleTable
            data={webhooks}
            columns={columns}
            title="Lista de Webhooks Cadastrados"
            showStatusColumn={false}
            showActionColumn={true}
            actionButtonLabel="Excluir"
            actionButtonIcon="pi pi-trash"
            onActionButtonClick={handleDeleteWebhook}
          />
        </>
      )}
      <WebhookModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSave={handleSaveWebhook}
        userId={userId}
      />
    </div>
  );
};

export default MerchantWebhook;
