import React, { useState } from "react";
import "primeicons/primeicons.css";
import logolight from "assets/logo-light.png";
import logodark from "assets/logo-dark.png";
import ThemeToggle from "components/ThemeToggle/ThemeToggle";
import "./SideBar.css";

const SideBar = ({
  firstSideBarIcons = [],
  secondSideBarIcons = [],
  onFirstIconClick,
  onSecondIconClick,
  activeIcon,
  activeSecondIcon,
  onThemeChange,
  isDarkMode,
}) => {
  const [isExpanded, setIsExpanded] = useState(false); // Estado para controlar a expansão

  return (
    <div
      className={`first-sidebar ${isExpanded ? "expanded" : ""}`}
      onMouseEnter={() => setIsExpanded(true)} // Expande ao passar o mouse
      onMouseLeave={() => setIsExpanded(false)} // Colapsa ao remover o mouse
    >
      {/* Logo que muda dinamicamente */}
      <div className="icon-list-container">
        <img
          src={isDarkMode ? logolight : logodark}
          alt="Logo"
          className="logo"
          style={{ width: "45px", marginBottom: "45px" }}
        />
        <ul className="icon-list">
          {firstSideBarIcons.map((icon, index) => (
            <li
              key={index}
              className={activeIcon === index ? "active" : ""}
              onClick={() => onFirstIconClick(index)}
            >
              <i className={`pi ${icon.iconClass}`} title={icon.label} />
              {isExpanded && <span className="icon-label">{icon.label}</span>}
            </li>
          ))}
        </ul>
      </div>

      {/* Lista de Ícones Inferiores e Switch */}
      <div className="icon-list-container">
        <ThemeToggle onThemeChange={onThemeChange} />
        <ul className="icon-list bottom-icons">
          {secondSideBarIcons.map((icon, index) => (
            <li
              key={index}
              className={activeSecondIcon === index ? "active" : ""}
              onClick={() => onSecondIconClick(index)}
            >
              <i className={`pi ${icon.iconClass}`} title={icon.label} />
              {isExpanded && <span className="icon-label">{icon.label}</span>}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
