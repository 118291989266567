import React from "react";
import "../SalesOverviewCard/SalesOverviewCard.css";

const InfosOverviewCard = ({
  title,
  salesData,
  totalSoldText = "Total vendido",
  icon,
}) => {
  const totalSales = salesData.pix + salesData.creditCard + salesData.boleto;

  return (
    <div className="sales-overview-card">
      {/* Cabeçalho */}
      <div className="card-header">
        <div className="balance-title">
          {icon && <span className="icon-card">{icon}</span>}
          <span className="title">{title}</span>
        </div>
      </div>
      <div className="card-body">
        <div className="total-sales">
          <h1 className="overview-balance">
            {totalSales}
          </h1>
        </div>
        <div className="total-sales-info">
          <span>{totalSoldText}</span>
        </div>
      </div>
    </div>
  );
};

export default InfosOverviewCard;