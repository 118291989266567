import React from "react";
import TaxCard from "components/DashboardComponents/TaxCard/TaxCard";
import RetainedCard from "components/DashboardComponents/RetainedCard/RetainedCard";
import "./AdminMerchantTax.css"; // Arquivo CSS para o componente
import { ReactComponent as CreditCardIcon } from "assets/icons/card-icon.svg";
import { ReactComponent as PixIcon } from "assets/icons/pix-icon.svg";
import { ReactComponent as BoletoIcon } from "assets/icons/boleto-icon.svg";

const AdminMerchantTax = () => {
  const retainedData = {
    pix: 1000, // Exemplo de valor retido por Pix
    creditCard: 1500, // Exemplo de valor retido por Cartão de Crédito
    boleto: 500, // Exemplo de valor retido por Boleto
  };

  return (
    <div className="merchant-tax-container">
      <h1 className="dash-title">Minhas taxas</h1>
      <div className="tax-content">
        <div className="tax-item">
          <TaxCard
            title="Pix"
            description="Receba pagamentos com cartão de crédito de forma segura e rápida."
            mainRate="6.99% + R$ 2,99 / transação (à vista)"
            reserve="Reserva financeira de 20%"
            icon={<PixIcon />} // Ícone SVG que você pode editar
            accordionTitle="Ver taxas parceladas"
            installmentRates={[
              "2x - 6x: 22.99% + R$ 2,99",
              "7x - 12x: 29.99% + R$ 2,99",
            ]}
            showAccordion={false} // Exibe o accordion com as taxas parceladas
          />
        </div>
        <div className="tax-item">
          <TaxCard
            title="Boleto"
            description="Receba pagamentos com cartão de crédito de forma segura e rápida."
            mainRate="6.99% + R$ 2,99 / transação (à vista)"
            reserve="Reserva financeira de 20%"
            icon={<BoletoIcon />} // Ícone SVG que você pode editar
            accordionTitle="Ver taxas parceladas"
            installmentRates={[
              "2x - 6x: 22.99% + R$ 2,99",
              "7x - 12x: 29.99% + R$ 2,99",
            ]}
            showAccordion={false} // Exibe o accordion com as taxas parceladas
          />
        </div>

        <div className="tax-item">
          <TaxCard
            title="Cartão de crédito"
            description="Receba pagamentos com cartão de crédito de forma segura e rápida."
            mainRate="6.99% + R$ 2,99 / transação (à vista)"
            reserve="Reserva financeira de 20%"
            icon={<CreditCardIcon />} // Ícone SVG que você pode editar
            accordionTitle="Ver taxas parceladas"
            installmentRates={[
              "2x - 6x: 22.99% + R$ 2,99",
              "7x - 12x: 29.99% + R$ 2,99",
            ]}
            showAccordion={true} // Exibe o accordion com as taxas parceladas
          />
        </div>
        <div className="tax-item">
          <RetainedCard
            title="Saldo Retido"
            description="Seu saldo retido em transações"
            retainedData={retainedData} // Valores de saldo retido por método
            additionalInfo="Selecione os métodos para visualizar o saldo retido"
          />
        </div>
      </div>
    </div>
  );
};

export default AdminMerchantTax;
