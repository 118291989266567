import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SelectButton } from "primereact/selectbutton";
import { Accordion, AccordionTab } from "primereact/accordion"; // Importando Accordion
import { Dropdown } from "primereact/dropdown";
import {
  getUserKycData,
  updateUserKycData,
  getUserBankAccount,
  updateUserBankAccount,
  getMerchantFees,
  updateMerchantFees
} from "services/authService";
import CustomInput from "components/CustomInput/CustomInput";
import PrimaryButton from "components/DashboardComponents/PrimaryButton/PrimaryButton";
import AdminCreditTax from "components/AdminControl/AdminCreditTax/AdminCreditTax";
import "./AdminMerchantDetails.css";

const AdminMerchantDetails = () => {
  const { userId } = useParams();
  const baseUrl = "https://unique-pag-kyc.s3.us-east-2.amazonaws.com/";

  const options = [
    { label: "Informações", value: "information" },
    { label: "Documentos", value: "documents" },
    { label: "Contas bancárias", value: "accounts" },
    { label: "Taxas", value: "tax" },
    { label: "Taxas de Crédito", value: "credit" },
  ];

  const statusOptions = [
    { label: "Excluído", value: "Deleted" },
    { label: "Pendente", value: "Awaiting" },
    { label: "Aprovado", value: "Approved" },
    { label: "Reprovado", value: "Reproved" },
    { label: "Revisão", value: "Revision" },
  ];

  const [selectedOption, setSelectedOption] = useState(options[3].value);
  const [merchantData, setMerchantData] = useState({
    fullName: "",
    cpfCnpj: "",
    phoneNumber: "",
    email: "",
    street: "",
    streetNumber: "",
    neighborhood: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    enStatusMerchant: "Awaiting",
    selfieUrl: "",
    selfieDocUrl: "",
    docFrontUrl: "",
    docBackUrl: "",
    contractUrl: "",
  });

  const [bankAccountData, setBankAccountData] = useState({
    bankName: "",
    bankCode: "",
    pixKey: "",
  });

  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        setLoading(true);
        setError(null);
    
        // Puxa os dados de KYC do usuário
        const data = await getUserKycData(userId);
        console.log("Dados de KYC recebidos:", data);
        setMerchantData((prevData) => ({
          ...prevData, // Preserva os dados atuais
          ...data, // Atualiza apenas os novos dados recebidos
        }));
      } catch (error) {
        setError(error.message);
        console.error("Erro ao buscar dados de KYC:", error);
      } finally {
        setLoading(false);
      }
    };
  
    const fetchBankAccountData = async () => {
      try {
        // Puxa os dados bancários do usuário
        const bankData = await getUserBankAccount(userId);
        console.log("Dados bancários recebidos:", bankData);
        setBankAccountData(bankData);
      } catch (error) {
        console.error("Erro ao buscar dados bancários:", error);
      }
    };
  
    const fetchTaxData = async () => {
      try {
        // Puxa as taxas do merchant
        const taxData = await getMerchantFees(userId);
        console.log("Dados de taxas recebidos:", taxData);
        setMerchantData((prevData) => ({
          ...prevData, // Preserva os dados anteriores
          feePix: taxData.feePix,
          reservePix: taxData.reservePix,
          feeCredit: taxData.feeCredit,
          reserveCredit: taxData.reserveCredit,
          feeTED: taxData.feeTED,
          reserveTED: taxData.reserveTED,
          feeOcult: taxData.feeOcult,
          creditReleaseDays: taxData.creditReleaseDays,
        }));
      } catch (error) {
        console.error("Erro ao buscar taxas:", error);
      }
    };
  
    // Função que faz todas as requisições simultaneamente
    const fetchAllData = async () => {
      setLoading(true);
      try {
        // Chama as funções de busca em paralelo
        await Promise.all([fetchMerchantData(), fetchBankAccountData(), fetchTaxData()]);
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
        setError("Erro ao carregar os dados.");
      } finally {
        setLoading(false);
      }
    };
  
    // Verifica se o userId está disponível e faz as requisições
    if (userId) {
      fetchAllData(); // Chama a função para buscar todas as informações
    }
  }, [userId]);
  
  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMerchantData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDropdownChange = (e) => {
    setMerchantData((prevData) => ({
      ...prevData,
      enStatusMerchant: e.value,
    }));
  };

  const handleBankInputChange = (e) => {
    const { name, value } = e.target;
  
    // Validação específica para pixKey
    if (name === "pixKey") {
      let cleanedValue = value;
  
      if (value.includes("@")) {
        // Chave Pix é um e-mail (mantém caracteres especiais)
        cleanedValue = value;
      } else if (/^\d{14}$/.test(value.replace(/\D/g, ""))) {
        // Chave Pix é um CNPJ (remove caracteres não numéricos)
        cleanedValue = value.replace(/\D/g, "");
      } else if (/^\d{11}$/.test(value.replace(/\D/g, ""))) {
        // Chave Pix é um CPF (remove caracteres não numéricos)
        cleanedValue = value.replace(/\D/g, "");
      } else if (/^\d{10}$/.test(value.replace(/\D/g, ""))) {
        // Chave Pix é um telefone (remove caracteres não numéricos)
        cleanedValue = value.replace(/\D/g, "");
      } else {
        // Chave Pix aleatória (permite letras, números e traços)
        cleanedValue = value.replace(/[^a-zA-Z0-9-]/g, "");
      }
  
      // Atualiza o estado com a chave Pix validada
      setBankAccountData((prevData) => ({
        ...prevData,
        [name]: cleanedValue,
      }));
  
      return;
    }
  
    // Atualiza o estado para outros campos bancários
    setBankAccountData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      await updateUserKycData(userId, merchantData);
      alert("Dados atualizados com sucesso!");
    } catch (error) {
      alert("Erro ao salvar as alterações.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveBankAccountChanges = async () => {
    setIsSaving(true);
    try {
      await updateUserBankAccount(userId, bankAccountData);
      alert("Dados bancários atualizados com sucesso!");
    } catch (error) {
      alert("Erro ao atualizar os dados bancários.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveTaxData = async () => {
    setIsSaving(true);
    try {
      await updateMerchantFees(userId, {
        feePix: merchantData.feePix,
        reservePix: merchantData.reservePix,
        feeCredit: merchantData.feeCredit,
        reserveCredit: merchantData.reserveCredit,
        feeTED: merchantData.feeTED,
        reserveTED: merchantData.reserveTED,
        feeOcult: merchantData.feeOcult,
        creditReleaseDays: merchantData.creditReleaseDays,
      });
      alert("Taxas atualizadas com sucesso!");
    } catch (error) {
      alert("Erro ao salvar as taxas.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.value);
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro: {error}</div>;
  }

  const getImageUrl = (path) => {
    return `${baseUrl}${path}`;
  };

  return (
    <div className="merchant-user-container">
      <div className="tab-switch">
        <SelectButton
          value={selectedOption}
          onChange={handleOptionChange}
          options={options}
          className="tab-switch-button disabled-select"
          optionLabel="label"
          buttonClassName={(option) =>
            selectedOption === option.value ? "" : "disabled-select"
          }
        />
      </div>

      <div className="select-container">
        {selectedOption === "information" && (
          <div className="information-details">
            <h2>Informações da Conta</h2>

            <div className="status-container">
              <h3 style={{ marginRight: "10px" }}>Status:</h3>
              <Dropdown
                value={merchantData.enStatusMerchant}
                options={statusOptions}
                onChange={handleDropdownChange}
                placeholder="Selecione o status do Merchant"
                className="status-dropdown"
              />
            </div>
            <div className="information-container">
              <div className="information-content information-content-1">
                <CustomInput
                  label="Nome/Razão Social"
                  name="fullName"
                  value={merchantData.fullName}
                  onChange={handleInputChange}
                  isRequired={true}
                />

                <CustomInput
                  label="Email"
                  name="email"
                  value={merchantData.email}
                  onChange={handleInputChange}
                  isRequired={true}
                />

                <div className="personal-information">
                  <CustomInput
                    label="CPF/CNPJ"
                    name="cpfCnpj"
                    value={merchantData.cpfCnpj}
                    onChange={handleInputChange}
                    isRequired={true}
                    style={{ marginRight: "10px" }}
                  />
                  <CustomInput
                    label="Telefone"
                    name="phoneNumber"
                    value={merchantData.phoneNumber}
                    onChange={handleInputChange}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="information-content information-content-2">
                <CustomInput
                  label="Rua"
                  name="street"
                  value={merchantData.street}
                  onChange={handleInputChange}
                  isRequired={true}
                />
                <div className="personal-information">
                  <CustomInput
                    label="Número"
                    name="streetNumber"
                    value={merchantData.streetNumber}
                    onChange={handleInputChange}
                    isRequired={true}
                    style={{ marginRight: "10px" }}
                  />
                  <CustomInput
                    label="Bairro"
                    name="neighborhood"
                    value={merchantData.neighborhood}
                    onChange={handleInputChange}
                    isRequired={true}
                  />
                </div>

                <div className="personal-information">
                  <CustomInput
                    label="Cidade"
                    name="city"
                    value={merchantData.city}
                    onChange={handleInputChange}
                    isRequired={true}
                    style={{ marginRight: "10px" }}
                  />

                  <CustomInput
                    label="Estado"
                    name="state"
                    value={merchantData.state}
                    onChange={handleInputChange}
                    isRequired={true}
                  />
                </div>

                <div className="personal-information">
                  <CustomInput
                    label="CEP"
                    name="zipCode"
                    value={merchantData.zipCode}
                    onChange={handleInputChange}
                    isRequired={true}
                    style={{ marginRight: "10px" }}
                  />

                  <CustomInput
                    label="País"
                    name="country"
                    value={merchantData.country}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <PrimaryButton
                label={isSaving ? "Salvando..." : "Salvar Alterações"}
                icon="pi pi-save"
                onClick={handleSaveChanges}
                className="save-button"
                disabled={isSaving}
              />
            </div>
          </div>
        )}

        {/* Seção de Documentos */}
        {selectedOption === "documents" && (
          <div className="documents-details">
            <h2>Documentos</h2>
            <Accordion multiple activeIndex={[0]}>
              <AccordionTab header="Selfie">
                {merchantData.selfieUrl ? (
                  <img
                    src={getImageUrl(merchantData.selfieUrl)}
                    alt="Selfie"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                ) : (
                  <p>Sem selfie enviada</p>
                )}
              </AccordionTab>

              <AccordionTab header="Selfie com Documento">
                {merchantData.selfieDocUrl ? (
                  <img
                    src={getImageUrl(merchantData.selfieDocUrl)}
                    alt="Selfie com Documento"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                ) : (
                  <p>Sem selfie com documento enviada</p>
                )}
              </AccordionTab>

              <AccordionTab header="Frente do Documento">
                {merchantData.docFrontUrl ? (
                  <img
                    src={getImageUrl(merchantData.docFrontUrl)}
                    alt="Frente do Documento"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                ) : (
                  <p>Sem documento frente enviado</p>
                )}
              </AccordionTab>

              <AccordionTab header="Verso do Documento">
                {merchantData.docBackUrl ? (
                  <img
                    src={getImageUrl(merchantData.docBackUrl)}
                    alt="Verso do Documento"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                ) : (
                  <p>Sem documento verso enviado</p>
                )}
              </AccordionTab>

              <AccordionTab header="Contrato Social (Pessoa Jurídica)">
                {merchantData.contractUrl ? (
                  <a
                    href={getImageUrl(merchantData.contractUrl)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visualizar Contrato (PDF)
                  </a>
                ) : (
                  <p>Sem contrato social enviado</p>
                )}
              </AccordionTab>
            </Accordion>
          </div>
        )}

        {/* Seção de Contas Bancárias */}
        {selectedOption === "accounts" && (
          <div className="accounts-details">
            <h2>Dados bancários</h2>

            <div className="information-container">
              <div className="information-content information-content-1">
                <div className="personal-information">
                  <CustomInput
                    label="Nome do Banco"
                    name="bankName"
                    value={bankAccountData.bankName}
                    onChange={handleBankInputChange}
                    isRequired={true}
                    style={{
                      marginRight: "10px",
                    }}
                  />

                  <CustomInput
                    label="Código do Banco"
                    name="bankCode"
                    value={bankAccountData.bankCode}
                    onChange={handleBankInputChange}
                    isRequired={true}
                  />
                </div>

                <CustomInput
                  label="Chave Pix"
                  name="pixKey"
                  value={bankAccountData.pixKey}
                  onChange={handleBankInputChange}
                  isRequired={true}
                />
              </div>
              <PrimaryButton
                label={isSaving ? "Salvando..." : "Salvar Dados Bancários"}
                icon="pi pi-save"
                onClick={handleSaveBankAccountChanges} // Botão para salvar dados bancários
                className="save-button"
                disabled={isSaving}
              />
            </div>
          </div>
        )}

        {/* Seção de Contas Bancárias */}
        {selectedOption === "tax" && (
          <div className="tax-details">
          <h2>Taxas do Cliente (Antecipação)</h2>
          <div className="tax-cards-selection">
            <div className="tax-card-selection">
              <p>Pix</p>
              <div className="tax-percent">
                <CustomInput
                  label="Taxa Pix (%)"
                  name="feePix"
                  value={merchantData.feePix}
                  onChange={handleInputChange}
                  isRequired={true}
                />
                <p>Sobre cada venda</p>
              </div>
              <CustomInput
                label="Reserva Pix (%)"
                name="reservePix"
                value={merchantData.reservePix}
                onChange={handleInputChange}
                isRequired={true}
              />
            </div>
        
            <div className="tax-card-selection">
              <p>Boleto</p>
              <div className="tax-percent">
                <CustomInput
                  label="Taxa Boleto (%)"
                  name="feeTED"
                  value={merchantData.feeTED}
                  onChange={handleInputChange}
                  isRequired={true}
                />
                <p>Sobre cada venda</p>
              </div>
              <CustomInput
                label="Reserva Boleto (%)"
                name="reserveTED"
                value={merchantData.reserveTED}
                onChange={handleInputChange}
                isRequired={true}
              />
            </div>
        
            <div className="tax-card-selection">
              <p>Cartão de Crédito</p>
              <div className="tax-percent">
                <CustomInput
                  label="Taxa Cartão (%)"
                  name="feeCredit"
                  value={merchantData.feeCredit}
                  onChange={handleInputChange}
                  isRequired={true}
                />
                <p>Sobre cada venda</p>
              </div>
              <CustomInput
                label="Reserva Cartão (%)"
                name="reserveCredit"
                value={merchantData.reserveCredit}
                onChange={handleInputChange}
                isRequired={true}
              />
            </div>
            <div className="tax-card-selection">
              <p>Taxa Oculta</p>
              <div className="tax-percent">
                <CustomInput
                  label="Taxa Oculta (%)"
                  name="feeOcult"
                  value={merchantData.feeOcult}
                  onChange={handleInputChange}
                  isRequired={true}
                />
                <p>Sobre cada venda</p>
              </div>
              <CustomInput
                label="Reserva Oculta (%)"
                name="reserveCredit"
                value="0"
                onChange={handleInputChange}
                isRequired={true}
                disabled
              />
            </div>
          </div>
        
          <PrimaryButton
            label={isSaving ? "Salvando..." : "Salvar Taxas"}
            icon="pi pi-save"
            onClick={handleSaveTaxData} // Função que você já tem para salvar
            className="save-button"
            disabled={isSaving}
            style={{ width: "80%" }}
          />
        </div>
        )}

        {/* Seção de Contas Bancárias */}
        {selectedOption === "credit" && (
          <div className="accounts-details">
            <h2>Taxas de Crédito</h2>
            <AdminCreditTax />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminMerchantDetails;
