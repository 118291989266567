import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from 'pages/Login/Login';
import AdminPanel from 'pages/Admin/AdminPanel';
import LoginAdmin from 'pages/LoginAdmin/LoginAdmin';
import MerchantPanel from 'pages/Merchant/MerchantPanel';
import KYCPage from 'pages/KYC/KYCPage';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import { ThemeProvider } from 'context/ThemeContext';
import 'styles/global.css';
import 'primeicons/primeicons.css'; 
import './App.css';
import MerchantDetails from "pages/Admin/MerchantDetails/MerchantDetails";

function App() {
  return (
    <ThemeProvider>
      <Router>
        <Routes>
          {/* Redireciona a rota raiz ("/") para "/login" */}
          <Route path="/" element={<Navigate to="/login" replace />} />

          {/* Demais rotas */}
          <Route path="/login" element={<Login />} />
          <Route path="/admin/login" element={<LoginAdmin />} />

          <Route 
            path="/admin" 
            element={
              <ProtectedRoute requiredRole="Admin">
                <AdminPanel />
              </ProtectedRoute>
            } 
          />

          <Route 
            path="/merchant" 
            element={
              <ProtectedRoute requiredRole="Merchant">
                <MerchantPanel />
              </ProtectedRoute>
            } 
          />

          <Route 
            path="/kyc" 
            element={<KYCPage />} 
          />

          <Route 
            path="/admin/clients/:userId" 
            element={<MerchantDetails />} 
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
