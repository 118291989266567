import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ConversionIndexCard = ({ title, percentage, description, color, helperText }) => {
  return (
    <div className="circular-item" style={{ display: "flex", width: "100%" ,alignItems: "center", margin: "0" }}>
      <div style={{ width: 100, height: 100, marginRight: ".2rem" }}>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles({
            textColor: color,
            pathColor: color,
            trailColor: "var(--icons-sidebar)",
          })}
        />
      </div>
      <div>
        <h3 style={{ margin: 0, fontSize: "1rem" }}>{title}</h3>
        <p style={{ margin: "0.2rem 0", fontSize: "0.8rem", color: "var(--text-color)" }}>{description}</p>
        <p style={{ margin: 0, fontSize: "0.8rem", color: color }}>{helperText}</p>
      </div>
    </div>
  );
};

export default ConversionIndexCard;
