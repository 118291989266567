import React from "react";
import "./SalesOverviewCard.css";
import { ReactComponent as DotsIcon } from "assets/icons/three-dots-vertical.svg";

const SalesOverviewCard = ({
  title,
  salesData,
  salesColor = "#000",
  totalSoldText = "Total vendido",
  icon,
  showDotButton = false,
  onDotButtonClick,
}) => {
  const formatToCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  return (
    <div className="sales-overview-card">
      <div className="card-header">
        <div className="balance-title">
          {icon && <span className="icon-card">{icon}</span>}
          <span className="title">{title}</span>
        </div>
        {showDotButton && (
          <div className="dot-button" onClick={onDotButtonClick}>
            <DotsIcon />
          </div>
        )}
      </div>

      <div className="card-body">
        <div className="total-sales">
          <h1 className="overview-balance">
            {formatToCurrency(salesData)}
          </h1>
        </div>

        <div className="total-sales-info">
          <span>{totalSoldText}</span>
        </div>
      </div>
    </div>
  );
};

export default SalesOverviewCard;
