import React from "react";
import './RetainedCard.css';
import { ReactComponent as TimeIcon } from 'assets/icons/stopwatch.svg';

const RetainedCard = ({
  title,
  description,
  retainedAmount = 0,
  additionalInfo,
}) => {
  return (
    <div className="retained-card">
      <div className="retained-card-header">
        <h2 className="retained-card-title">{title}</h2>
        <div className="tax-card-icon">
          <TimeIcon className="retained-card-icon-image" />
        </div>
      </div>
      <p className="retained-card-description">{description}</p>
      <h3 className="retained-card-amount">{retainedAmount}</h3>
      <p className="retained-card-additional">{additionalInfo}</p>
    </div>
  );
};

export default RetainedCard;
